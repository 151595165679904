import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogContent,
  DialogButtons,
  DialogHeader,
  TextField,
  Spinner,
} from 'factor';
import trim from 'lodash/trim';
import { validationUtils } from 'iqm-framework';

import { WithResponse } from 'models/Response';
import { AUDIO_CREATIVE_ID, Creative, VIDEO_CREATIVE_ID } from 'models/Creative';
import { AppState } from 'models/Store';
import { CreativesPlacementMapping, ExistingCampaignData } from 'models/ExistingCampaign';
import { User } from 'models/User';
import { Open, toastActions } from 'store/toast/actions';
import { CreativesActions, creativesActions } from 'store/creatives/actions';
import { API } from 'api';
import {
  BulkEditPixelResponse,
  BulkUpdateResponse,
  DuplicateCreativesResponse,
} from 'api/Creatives';
import { validateUrl } from 'utils/validationRules';
import { renderContentCell } from '../SelectCreativesPopup/creativeTableView/creativeTable/creativeTableOptions/creativeTableMappers';

import styles from './styles.module.scss';

enum URL_POPUP {
  CLOSED = 'CLOSED',
  FIELD_POPUP = 'FIELD_POPUP',
  OPTIONS_POPUP = 'OPTIONS_POPUP',
}

enum CLICK_URL_OPTIONS {
  UPDATE_AND_APPLY = 'UPDATE_AND_APPLY',
  COPY_AND_UPDATE = 'COPY_AND_UPDATE',
}

interface Props {
  creatives: Creative[];
  tableSelectedCreatives: { [key: number]: boolean };
  editableCampaign: ExistingCampaignData | null;
  user: User;
  openToast: Open['open'];
  selectCreatives: CreativesActions['selectCreatives'];
  setCreativesPlacementMapping: CreativesActions['setCreativesPlacementMapping'];
}

const BulkURLUpdateComponent = (props: Props) => {
  const {
    creatives,
    tableSelectedCreatives,
    openToast,
    selectCreatives,
    editableCampaign,
    user,
    setCreativesPlacementMapping,
  } = props;

  const [pixelURLPopup, setPixelURLPopup] = useState<URL_POPUP>(URL_POPUP.CLOSED);
  const [clickURLPopup, setClickURLPopup] = useState<URL_POPUP>(URL_POPUP.CLOSED);
  const [pixelURL, setPixelURL] = useState<string>('');
  const [clickURL, setClickURL] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<CLICK_URL_OPTIONS>(
    CLICK_URL_OPTIONS.UPDATE_AND_APPLY,
  );
  const [isApiInProgress, setIsApiInProgress] = useState(false);
  const [apiStatus, setApiStatus] = useState<string>('loading');
  const shouldReset = useRef(false);

  const selectedCreative = React.useMemo(() => {
    const selectedIds = Object.keys(tableSelectedCreatives)
      .map((id) => Number(id))
      .filter((id) => tableSelectedCreatives[id]);
    return creatives.filter((creative) => selectedIds.includes(creative.id));
  }, [creatives, tableSelectedCreatives]);

  useEffect(() => {
    return () => {
      setPixelURL('');
      setClickURL('');
      setSubmitted(false);
    };
  }, []);

  const isDialogOpen = useMemo(
    () =>
      pixelURLPopup === URL_POPUP.FIELD_POPUP ||
      clickURLPopup === URL_POPUP.FIELD_POPUP ||
      clickURLPopup === URL_POPUP.OPTIONS_POPUP,
    [clickURLPopup, pixelURLPopup],
  );

  const handleClickURLPopup = () => {
    setClickURLPopup(URL_POPUP.FIELD_POPUP);
  };

  const handlePixelURLPopup = () => {
    setPixelURLPopup(URL_POPUP.FIELD_POPUP);
  };

  const reset = useCallback(() => {
    shouldReset.current = false;
    setSubmitted(false);
    setPixelURL('');
    setClickURL('');
    setIsApiInProgress(false);
    setSelectedAction(CLICK_URL_OPTIONS.UPDATE_AND_APPLY);
    if (pixelURLPopup === URL_POPUP.FIELD_POPUP) {
      setPixelURLPopup(URL_POPUP.CLOSED);
    } else {
      setClickURLPopup(URL_POPUP.CLOSED);
    }
  }, [pixelURLPopup]);

  const onSpinnerClose = useCallback(() => {
    setIsApiInProgress(false);
    setApiStatus('loading');
    if (shouldReset.current) {
      reset();
    }
  }, [reset]);

  const prepareURL = (url: string) => {
    const trimmedURL = trim(url);
    return trimmedURL.startsWith('http') ? trimmedURL : `http://${trimmedURL}`;
  };

  const handlePixelURLUpdate = async () => {
    setApiStatus('loading');
    setIsApiInProgress(true);

    const pixelURLToSave = prepareURL(pixelURL);

    try {
      const res: WithResponse<BulkEditPixelResponse> = await API.Creatives.BulkUpdatePixelURL({
        creativeIds: creatives
          .filter((crt) => tableSelectedCreatives[crt.id])
          .map((crt) => crt.id)
          .join(','),
        pixelURL: pixelURLToSave,
      });

      if (res.success) {
        openToast('Pixel URL changed successfully for selected creatives.');
        const creativesToUpdate = creatives.map((crt) => ({
          ...crt,
          ...(tableSelectedCreatives[crt.id] && crt.pixelUrls
            ? { pixelUrls: [pixelURLToSave] }
            : {}),
        }));

        selectCreatives(creativesToUpdate);
        shouldReset.current = true;
        setApiStatus('success');
      } else {
        onSpinnerClose();
      }
    } catch (e) {
      setApiStatus('fail');
      openToast((e as any)?.errorMsg || 'Error updating Pixel URL');
    }
  };

  const updateSelectedCreativesWithClickURL = (savedClickURL: string, updatedData: number[]) => {
    const creativesToUpdate = creatives.map((crt) => ({
      ...crt,
      ...(updatedData.includes(crt.id)
        ? {
            clickUrl: savedClickURL,
          }
        : {}),
    }));
    selectCreatives(creativesToUpdate);
  };

  const fetchSelectedCreatives = async (
    newCreativeIds: number[],
    oldCreativeIds: number[],
  ): Promise<Creative[]> => {
    const creativeRes = await API.Creatives.FetchCreativeList({
      creativeIds: newCreativeIds.join(','),
    });
    const fetchedCreatives: Creative[] = creativeRes.responseObject.data;
    const replacedCreativeIds = oldCreativeIds.filter((id) => !newCreativeIds.includes(id));

    const updatedAndRetainedCreatives = creatives
      .filter((crt) => !replacedCreativeIds.includes(crt.id))
      .map((crt) => {
        const updatedCrt = fetchedCreatives.find((newCrt) => newCrt.id === crt.id);
        if (!updatedCrt) {
          return crt;
        }
        return updatedCrt;
      });

    const newCreatives = fetchedCreatives.filter(
      (crt) => !updatedAndRetainedCreatives.find((existing) => existing.id === crt.id),
    );

    const creativesToUpdate = updatedAndRetainedCreatives.concat(newCreatives);
    selectCreatives(creativesToUpdate);
    return creativesToUpdate;
  };

  const isDifferentApprovalValueSelected = React.useMemo(() => {
    return (
      selectedCreative.some((crt) => crt.approvalRequiredPostUrlUpdate === true) &&
      selectedCreative.some((crt) => crt.approvalRequiredPostUrlUpdate === false)
    );
  }, [selectedCreative]);

  const isAllSelectedRequireApproval = React.useMemo(() => {
    return selectedCreative.every((crt) => crt.approvalRequiredPostUrlUpdate === true);
  }, [selectedCreative]);

  const isAllSelectedDoesNotRequireApproval = React.useMemo(() => {
    return selectedCreative.every((crt) => crt.approvalRequiredPostUrlUpdate === false);
  }, [selectedCreative]);

  const handleClickURLUpdate = async (force = false) => {
    setApiStatus('loading');
    setIsApiInProgress(true);

    const clickURLToSave = prepareURL(clickURL);
    const creativeIdsArr = creatives
      .filter((crt) => tableSelectedCreatives[crt.id])
      .map((crt) => crt.id);

    const creativeIds = creativeIdsArr.join(',');

    try {
      const res: WithResponse<BulkUpdateResponse> = await API.Creatives.BulkUpdateClickURL({
        creativeIds,
        clickURL: clickURLToSave,
        ...(editableCampaign ? { campaignId: editableCampaign.id } : {}),
        ...(force ? { confirmStatusChange: true } : {}),
      });

      if (res.success) {
        openToast(<div>{res.data?.message}</div>);
        const successIds = creatives
          .filter((crt) => tableSelectedCreatives[crt.id])
          .map((crt) => crt.id);

        updateSelectedCreativesWithClickURL(clickURLToSave, successIds);

        if (force) {
          fetchSelectedCreatives(successIds, successIds);
        } else {
          updateSelectedCreativesWithClickURL(clickURLToSave, successIds);
        }

        shouldReset.current = true;
        setApiStatus('success');
      }
    } catch (e) {
      setApiStatus('fail');
      openToast((e as any).errorMsg || 'Error updating Click URL');
    }
  };

  const handleSubmit = () => {
    if (pixelURLPopup === URL_POPUP.FIELD_POPUP) {
      handlePixelURLUpdate();
    } else if (clickURLPopup === URL_POPUP.FIELD_POPUP) {
      if (isAllSelectedDoesNotRequireApproval) {
        handleClickURLUpdate();
      }
      if (isAllSelectedRequireApproval) {
        setClickURLPopup(URL_POPUP.OPTIONS_POPUP);
      }
    }
  };

  const handleClickOptionsSubmit = async () => {
    if (selectedAction === CLICK_URL_OPTIONS.UPDATE_AND_APPLY) {
      handleClickURLUpdate(true);
    } else if (user) {
      setIsApiInProgress(true);
      const clickURLToSave = prepareURL(clickURL);

      try {
        const creativeIdsArr = creatives
          .filter((crt) => tableSelectedCreatives[crt.id])
          .map((crt) => crt.id);

        const creativeIds = creativeIdsArr.join(',');
        const res: WithResponse<DuplicateCreativesResponse> =
          await API.Creatives.MakeDuplicateCreatives({
            creativeIds,
            clickURL: clickURLToSave,
          });

        if (res.success && res?.data?.message) {
          openToast(res.data?.message);
          const creativesToUpdate = await fetchSelectedCreatives(
            res.data.duplicatedCreativeIds,
            creativeIdsArr,
          );
          if (
            [VIDEO_CREATIVE_ID, AUDIO_CREATIVE_ID].includes(creativesToUpdate[0].creativeTypeId)
          ) {
            const placementData = creativesToUpdate.reduce(
              (acc: CreativesPlacementMapping, crt) => {
                acc[String(crt.id)] = 0;
                return acc;
              },
              {} as CreativesPlacementMapping,
            );
            setCreativesPlacementMapping(placementData);
            shouldReset.current = true;
            setApiStatus('success');
          }
        }
      } catch (e) {
        setApiStatus('fail');
        openToast('Error duplicating creatives.');
      }
    }
  };

  const popupTypeName = pixelURLPopup === URL_POPUP.FIELD_POPUP ? 'Pixel' : 'Click';

  const getDialogContent = () => {
    if (isApiInProgress) {
      return (
        <div className={styles.spinner}>
          <Spinner status={apiStatus} onClose={onSpinnerClose} />
        </div>
      );
    }
    return pixelURLPopup === URL_POPUP.FIELD_POPUP || clickURLPopup === URL_POPUP.FIELD_POPUP ? (
      <div>
        <div className="mt-2">
          Enter the {popupTypeName} URL you want to apply to selected creatives
        </div>
        <div className={styles.urlFieldWrapper}>
          <TextField
            label={`${popupTypeName} URL`}
            placeholder={`Enter ${popupTypeName} URL`}
            value={popupTypeName === 'Pixel' ? pixelURL : clickURL}
            onChange={popupTypeName === 'Pixel' ? setPixelURL : setClickURL}
            validateOnInit
            inputAttributes={{
              autoFocus: true,
            }}
            validationRules={[
              {
                ...validateUrl(true),
                name: `${popupTypeName} URL`,
              },
            ]}
            validationKeys={[submitted]}
          />
        </div>
      </div>
    ) : (
      <div>
        <div>
          The following creatives are being used in other campaigns. If you update, they will go for
          re-approval and the effect will be applied in all the campaigns where they are used.
        </div>
        <div className={styles.failedCreativesWrapper}>
          {selectedCreative?.map((crt) => (
            <div className={styles.failedCreativeListItem} key={crt.id}>
              <div>
                <div className={styles.failedCreativeLabel}>ID</div>
                <div>{crt.id}</div>
              </div>
              <div className={styles.failedCreativePreview}>{renderContentCell(crt)}</div>
              <div>
                <div className={styles.failedCreativeLabel}>Name</div>
                <div>{crt.name}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex">
          <div className="d-flex flex-column">
            <div className={styles.radio}>
              <input
                type="radio"
                name="CLICK_URL_OPTIONS"
                id={CLICK_URL_OPTIONS.UPDATE_AND_APPLY}
                style={{ display: 'none' }}
                checked={selectedAction === CLICK_URL_OPTIONS.UPDATE_AND_APPLY}
                onChange={() => setSelectedAction(CLICK_URL_OPTIONS.UPDATE_AND_APPLY)}
              />
              <label htmlFor={CLICK_URL_OPTIONS.UPDATE_AND_APPLY}>
                <strong>Update creatives and apply the change to campaign</strong>
              </label>
            </div>
            <label className={styles.optionHelpText} htmlFor={CLICK_URL_OPTIONS.UPDATE_AND_APPLY}>
              Apply the change to selected creatives. The change gets reflected in their associated
              campaigns including the running campaigns
            </label>
          </div>
          <div className="d-flex flex-column">
            <div className={styles.radio}>
              <input
                type="radio"
                name="CLICK_URL_OPTIONS"
                id={CLICK_URL_OPTIONS.COPY_AND_UPDATE}
                style={{ display: 'none' }}
                checked={selectedAction === CLICK_URL_OPTIONS.COPY_AND_UPDATE}
                onChange={() => setSelectedAction(CLICK_URL_OPTIONS.COPY_AND_UPDATE)}
              />
              <label htmlFor={CLICK_URL_OPTIONS.COPY_AND_UPDATE}>
                <strong>Create a copy and update</strong>
              </label>
            </div>
            <label className={styles.optionHelpText} htmlFor={CLICK_URL_OPTIONS.COPY_AND_UPDATE}>
              Create a duplicate copy of the selected creatives and apply the change. All associated
              campaigns remain untouched. It won't affect the running campaigns
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isDifferentApprovalValueSelected === false && (
        <Button className="btn-square _conflower-blue mr-2" onClick={handleClickURLPopup}>
          Edit Click URL
        </Button>
      )}
      {creatives[0].creativeType.toLowerCase() === 'image' && (
        <Button className="btn-square _conflower-blue mr-2" onClick={handlePixelURLPopup}>
          Edit Pixel URL
        </Button>
      )}
      <Dialog open={isDialogOpen} className={styles.dialogWrapper}>
        <DialogHeader>
          <h2>Edit {popupTypeName} URL</h2>
        </DialogHeader>
        <DialogContent>
          {getDialogContent()}
          <div className={styles.dialogButtons}>
            <DialogButtons
              buttons={[
                {
                  title: 'Cancel',
                  handler: () => {
                    reset();
                  },
                  className: '_md',
                  disabled: isApiInProgress,
                },
                {
                  title: 'Update',
                  handler: () => {
                    if (clickURLPopup === URL_POPUP.OPTIONS_POPUP) {
                      handleClickOptionsSubmit();
                    } else {
                      setSubmitted(true);
                      handleSubmit();
                    }
                  },
                  className: 'btn-square _conflower-blue _filled _md',
                  disabled:
                    pixelURLPopup === URL_POPUP.FIELD_POPUP
                      ? !trim(pixelURL).length ||
                        !validationUtils.isValidURL(pixelURL) ||
                        isApiInProgress
                      : !trim(clickURL).length ||
                        !validationUtils.isValidURL(clickURL) ||
                        isApiInProgress,
                },
              ]}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapState = (state: AppState) => ({
  editableCampaign: state.app.editableCampaign,
  user: state.auth.userData,
});

const mapAction = {
  openToast: toastActions.open,
  selectCreatives: creativesActions.selectCreatives,
  setCreativesPlacementMapping: creativesActions.setCreativesPlacementMapping,
};

export const BulkURLUpdate = connect(mapState, mapAction)(BulkURLUpdateComponent);
