import React from 'react';
import {
  CreativeAudio,
  CreativeHTML,
  CreativeImage,
  CreativeNative,
  CreativeVideo,
} from '@applift/icons';
import { CREATIVE_ID_FROM_NAME } from 'constants/creatives';
import {
  Creative,
  creativeStatusMappingById,
  creativeTypeMappingById,
  NewCreativeObjectType,
  TCreativeTypeIds,
} from 'models/Creative';
import moment from 'moment';

export const getCreativeTypeIcon = (creativeTypeId: number) => {
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Image) {
    return <CreativeImage fontSize={24} sx={{ textColor: 'primary-600' }} />;
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Video) {
    return <CreativeVideo fontSize={24} sx={{ textColor: 'primary-600' }} />;
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Audio) {
    return <CreativeAudio fontSize={24} sx={{ textColor: 'primary-600' }} />;
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.HTML) {
    return <CreativeHTML fontSize={24} sx={{ textColor: 'primary-600' }} />;
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Native) {
    return <CreativeNative fontSize={24} sx={{ textColor: 'primary-600' }} />;
  }
  return null;
};

export const transformNewCreativeDataToOld = (newCreativeData: NewCreativeObjectType): Creative => {
  const oldCreativeData: Creative = {
    clickUrl: newCreativeData.clickUrl,
    createdOn: newCreativeData.createdAt,
    created: moment(newCreativeData.createdAt).format('DD MMMM YYYY'), // check if nessary
    creativeSource: newCreativeData.creativeSource,
    creativeThumbnailSource: newCreativeData.creativeThumbnailSource,
    creativeType:
      creativeTypeMappingById[
        newCreativeData.platformCreativeTypeId as keyof typeof creativeTypeMappingById
      ].name,
    creativeTypeId: newCreativeData.platformCreativeTypeId as TCreativeTypeIds,
    height: newCreativeData.creativeHeight as number,
    id: newCreativeData.creativeId,
    name: newCreativeData.creativeName,
    status:
      creativeStatusMappingById[
        newCreativeData.creativeStatusId as keyof typeof creativeStatusMappingById
      ].name,
    uuid: String(newCreativeData.userDetails?.uowId), // no sure about this check if it is getting used anywhere
    width: newCreativeData.creativeWidth as number,
    creativeCardSource: newCreativeData.creativeCardSource,
    duration: newCreativeData.duration as number,
    isTransformed: newCreativeData.isTransformed,
    campaignCount: newCreativeData?.campaignDetails?.campaignIds?.length ?? 0,
    pixelUrls: newCreativeData.pixelUrl ? [newCreativeData.pixelUrl] : [],
    // isHeader: boolean, // check if it is used at any critical place
    creativeScreenshotSource: newCreativeData.creativeThumbnailSource, // check if it is used at any critical place
    previewFlag: newCreativeData.creativePreviewFlag as any,
    nativeMediaType: newCreativeData.subMediaType,
    externalCreativeId: newCreativeData.externalCreativeId,
    campaignDetails: newCreativeData.campaignDetails,
    userDetails: newCreativeData.userDetails,
    organizationDetails: newCreativeData.organizationDetails,
    approvalRequiredPostUrlUpdate: newCreativeData.approvalRequiredPostUrlUpdate,
  };
  return oldCreativeData;
};
