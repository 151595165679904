import React from 'react';
import { Icon } from 'factor';
import { Creative } from 'models/Creative';

import WithErrorPlaceholderImage from '../../../SelectCreativesPopup/withErrorPlacehoderImage/WithErrorPlaceholderImage';

import styles from './index.module.scss';

export const AUDIO_IS_PROCESSING = 'Audio is processing...';
export const AUDIO_LOADING_IS_FAILED = 'Audio loading is failed';
export const VIDEO_IS_PROCESSING = 'Video is processing...';
export const VIDEO_LOADING_IS_FAILED = 'Video loading is failed';

type TProps = {
  creative: Creative;
  onClick: any;
};

export const AudioCell = (props: TProps) => {
  const { creative, onClick } = props;
  const { creativeThumbnailSource } = creative;

  const creativeId = String(creative.id);

  return (
    <div className={styles.noDataWrapper}>
      <label className={styles.playButton} htmlFor={creativeId} onClick={() => onClick(creative)}>
        {creativeThumbnailSource ? (
          <WithErrorPlaceholderImage
            src={creative.creativeThumbnailSource}
            className={styles.maxHeight80}
          />
        ) : (
          <span className={styles.playIconWrapper}>
            <Icon name="VoiceXS" className={styles.playIcon} />
          </span>
        )}
      </label>
      <input className={styles.radioInput} type="radio" name="playButton" id={creativeId} />
    </div>
  );
};
