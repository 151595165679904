import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { ApiService } from 'iqm-framework';
import { Icon } from 'factor';
import isEqual from 'lodash/isEqual';

import { AppState } from 'models/Store';
import { Creative } from 'models/Creative';
import { User } from 'models/User';
import { parseVASTDAAST, isFlash } from 'utils/VastDaastParser';
import { API } from 'api';
import { localStorageService } from 'services/localStorage';

import WithErrorPlaceholderImage from '../../../SelectCreativesPopup/withErrorPlacehoderImage/WithErrorPlaceholderImage';

import styles from './index.module.scss';

type TProps = {
  creative: Creative;
  onClick: any;
  user: User | null;
};

const getDelayForRetry = (retryCount: number) => {
  const milliseconds = [0, 500, 1000, 3000, 5000, 10000, 60000, 180000, 300000, 600000];
  return retryCount >= milliseconds.length
    ? milliseconds[milliseconds.length - 1]
    : milliseconds[retryCount];
};

export const VIDEO_LOADING_IS_FAILED = 'Video loading is failed';

const STORAGE_NAME = 'VIDEO_CREATIVE';

const VideoCellComponent = (props: TProps) => {
  const { creative, onClick, user } = props;
  const { creativeThumbnailSource } = creative;
  const [source, setSource] = useState('');
  const [isDataReady, setDataReady] = useState(false);
  const allowTimeout = useRef<boolean>(true);
  const currentTimeout = useRef<number | null>(null);
  const userRef = useRef<User | null>(null);

  const checkCreativeCloudfrontStatus = (creative: Creative, retryCount: number, user: User) => {
    if (allowTimeout.current) {
      const timeout = window.setTimeout(() => {
        API.Creatives.FetchCreativeList({
          creativeIds: creative.id.toString(),
        }).then((response) => {
          if (allowTimeout.current) {
            const data = response?.responseObject?.data;
            const statusCode = response?.statusCode;
            if (statusCode === 200 && data && data.length) {
              //  && data[0].isTransformed
              setDataReady(true);
              try {
                localStorageService.saveLocal(
                  `${STORAGE_NAME}_${user.userId}_${creative.id}`,
                  true,
                );
              } catch (err) {
                // Avoid crash if localStorage is full.
              }
            } else {
              checkCreativeCloudfrontStatus(creative, retryCount + 1, user);
            }
          }
        });
      }, getDelayForRetry(retryCount));
      currentTimeout.current = timeout;
    }
  };

  useEffect(() => {
    if (!creative || !user) {
      return undefined;
    }
    userRef.current = user;

    const isPreviewReady = () => {
      if (creative.previewFlag) {
        setDataReady(true);
      }
    };

    const loadMediaSource = async () => {
      const sourceResponse =
        creative?.creativeSource || (await ApiService.GetCreativeMediaSource(creative.id));

      if (sourceResponse) {
        const parsed = JSON.parse(sourceResponse);
        const dastSource = parsed[Object.keys(parsed)[0]];
        const link = await parseVASTDAAST(dastSource);
        if (link && !isFlash(link) && user) {
          setSource(link);

          const storedCloudfrontStatus = localStorageService.getLocal(
            `${STORAGE_NAME}_${user.userId}_${creative.id}`,
          );
          if (storedCloudfrontStatus && storedCloudfrontStatus.data) {
            setDataReady(true);
          } else {
            checkCreativeCloudfrontStatus(creative, 0, user);
          }
        } else {
          setDataReady(true);
        }
      }
    };

    loadMediaSource();
    isPreviewReady();

    return () => {
      allowTimeout.current = false;
      if (currentTimeout.current) {
        window.clearTimeout(currentTimeout.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentTimeout.current && !isEqual(user, userRef.current)) {
      allowTimeout.current = false;
      window.clearTimeout(currentTimeout.current);
      currentTimeout.current = null;
    }
  }, [user]);

  const renderImage = () => {
    if (creativeThumbnailSource) {
      return (
        <WithErrorPlaceholderImage
          src={creative.creativeThumbnailSource}
          className={styles.maxHeight80}
        />
      );
    }
    return (
      <div className={styles.noDataWrapper}>
        <Icon name="NoImage" />
      </div>
    );
  };

  return (
    <div
      onClick={
        isDataReady
          ? () => {
              onClick({
                ...creative,
                creativeSource: source || null,
              });
            }
          : () => {}
      }
      className={`${styles.maxHeight80} ${styles.withPointer}`}
    >
      {!isDataReady && (
        <div className={`${styles.process} ${styles.videoLoadingWrapper}`}>
          <span className={styles.processText}>Video is processing...</span>
        </div>
      )}
      {renderImage()}
    </div>
  );
};

const mapState = (state: AppState) => {
  return {
    user: state.auth.userData,
  };
};

export const VideoCell = connect(mapState)(VideoCellComponent);
