import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from '@applift/factor';

import { getCreativeListKey, getCreativesTypes } from 'api/QueryKeys';
import { WithResponse } from 'models/Response';
import { CreativeListParams } from 'models/Creative';
import {
  BulkUpdateClickURL,
  BulkUpdatePixelURL,
  fetchCreativeListQuery,
  FetchCreativeTypes,
  MakeDuplicateCreatives,
  BulkUpdatePixelURLRequestData,
  BulkUpdateResponse,
  DuplicateCreativesResponse,
} from 'api/Creatives';

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e?.errorObjects?.[0]?.error
      ? (e.errorObjects[0].error as string)
      : 'Something went wrong. Please try after sometime.',
    {
      variant: 'error',
    },
  );

export const useCreativeList = (
  creativesListParams: CreativeListParams,
  queryParams?: { enabled?: boolean; onSuccess?: (arg: any) => void },
) => {
  const data = useQuery(
    // @ts-ignore
    getCreativeListKey.keys('fetchCreativeList', creativesListParams),
    fetchCreativeListQuery,
    {
      onSuccess: queryParams?.onSuccess,
      enabled: queryParams?.enabled,
      onError,
      staleTime: 0,
    },
  );

  return data;
};

export const useCreativeTypes = () => {
  const data = useQuery(getCreativesTypes.keys('getCreativesTypes'), FetchCreativeTypes, {
    onError,
  });
  return data;
};

export const useUpdateClickUrl = ({
  onSuccess,
  onError,
}: {
  onSuccess: (response: WithResponse<BulkUpdateResponse>, request: any) => void;
  onError: (response: any) => void;
}) => {
  const mutationResult = useMutation(BulkUpdateClickURL, {
    mutationKey: ['bulkUpdateClickURL'],
    onSuccess,
    onError,
  });

  return mutationResult;
};

export const useUpdatePixelUrl = ({
  onSuccess,
  onError,
}: {
  onSuccess: (response: any, request: BulkUpdatePixelURLRequestData) => void;
  onError: (response: any) => void;
}) => {
  const mutationResult = useMutation(BulkUpdatePixelURL, {
    mutationKey: ['bulkUpdatePixelURL'],
    onSuccess,
    onError,
  });
  return mutationResult;
};

export const useDuplicateCreatives = ({
  onSuccess,
}: {
  onSuccess: (response: WithResponse<DuplicateCreativesResponse>, request: any) => void;
}) => {
  const mutationResult = useMutation(MakeDuplicateCreatives, {
    mutationKey: ['MakeDuplicateCreatives'],
    onSuccess,
    onError,
  });
  return mutationResult;
};
