import * as React from 'react';
import { CreativePreview } from '@applift/platform-creatives';
import { Box } from '@applift/factor';
import { Audio, Show } from '@applift/icons';

import styles from './styles.module.scss';

export const CreativePreviewCell = (props: { data: any }) => {
  const { data } = props;
  const { creativeStatus } = data;

  const [showCreativePreview, setShowCreativePreview] = React.useState<boolean>(false);

  const handleOpenPreview = React.useCallback(() => {
    if (data.id) {
      setShowCreativePreview(true);
    }
  }, [data]);

  const renderContentCell = React.useCallback(
    (item: any) => {
      const { creativeThumbnailSource } = item;

      if (creativeThumbnailSource) {
        return (
          <Box
            className={`${styles.noDataWrapper} ${styles.clickable}`}
            onClick={() => {
              handleOpenPreview();
            }}
          >
            <img
              className={styles.maxHeight80}
              style={{ cursor: 'pointer' }}
              src={creativeThumbnailSource}
              alt=""
            />
            <Box className={styles.imageOverlay}>
              <Box>
                <Show fontSize={20} sx={{ textColor: 'neutral-0' }} />
                <Box>Preview</Box>
              </Box>
            </Box>
          </Box>
        );
      }

      return (
        <Box
          className={`${styles.noDataWrapper} ${styles.clickable}`}
          onClick={() => {
            handleOpenPreview();
          }}
        >
          <Audio fontSize={20} />
        </Box>
      );
    },
    [handleOpenPreview],
  );

  if (data.total) {
    return null;
  }

  if (creativeStatus === 'Failed') {
    return <Box className={styles.noDataWrapper}>-</Box>;
  }

  return (
    <>
      <Box
        className={styles.maxHeight80}
        sx={{
          width: 100,
          height: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {renderContentCell(data)}
      </Box>
      {showCreativePreview ? (
        <CreativePreview
          open
          creativeId={data.id}
          view="preview"
          onClose={() => setShowCreativePreview(false)}
        />
      ) : null}
    </>
  );
};
