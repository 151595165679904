import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import { CreativeListParams } from 'models/Creative';

import { Option } from 'models/Option';
import { GetResponse, WithResponse } from 'models/Response';
import { Logger } from 'utils/logger';
import { transformNewCreativeDataToOld } from 'utils/creatives';
import { getInstance } from './Instance';
import { getCreativeListKey } from './QueryKeys';

type Response = {
  name: string;
  id: number;
  rtbCampaignTypeId?: number;
};

interface CreativeTypeStaticObject {
  displayName: string;
  id: number;
  name: string;
  order: number;
  rtbCreativeTypeId: null | number;
}

type CreativeTypeApiResponse = {
  creativeTypeList: CreativeTypeStaticObject[];
  filteredRecords: number;
  totalRecords: number;
};

export const FetchCreativeTypes = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<WithResponse<CreativeTypeApiResponse>> = await getInstance().get(
      '/v3/crt/master/static/creative-types',
    );

    return (
      response?.data?.data?.creativeTypeList.map((i) => ({
        label: i.displayName,
        value: i.id,
        rtbCampaignTypeId: i.rtbCreativeTypeId || -1,
      })) ?? []
    );
  } catch (e) {
    Logger.log('Error while getting creative types', e);
    return [];
  }
};

export const FetchRtbTypes = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<GetResponse<Response[]>> = await getInstance().get(
      '/v2/master/rtbTypes',
    );

    return response.data.responseObject.map((i) => ({
      label: i.name,
      value: i.id,
    }));
  } catch (e) {
    Logger.log('Error while getting creative types', e);
    return [];
  }
};

type CreativeStatusesCountParams = {
  creativeTypeIds?: string;
};

export const FetchCreativeStatusesCount = async (
  params?: CreativeStatusesCountParams,
): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<Response[]>> = await getInstance().get(
      'v2/crt/creativeStatus/creativeCount',
      {
        params,
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting creative statuses count', e);
    return [];
  }
};

export const FetchCreativeList = async (params: CreativeListParams): Promise<any> => {
  const {
    pageNo,
    noOfEntries,
    searchField,
    creativeTypeIds,
    statusIds,
    creativeIds,
    sortBy,
    sortType,
    creativeGroupId,
    campaignIds,
    owIds,
    activeCreatives,
    createdAfter,
    allOwIds,
    includeOrganizationDetails,
    includeUserDetails,
  } = params;
  const creativeIdsArr = creativeIds?.split(',').map((id) => Number(id));
  const creativeTypeIdsArr = creativeTypeIds
    ?.split(',')
    .filter((id) => Boolean(id.trim()))
    .map((id) => Number(id));
  const sortingString = sortType === 'desc' ? `-${sortBy}` : `+${sortBy}`;
  const request = {
    ...(typeof creativeGroupId === 'number' ? { creativeGroupId } : {}),
    ...(searchField ? { searchField } : {}),
    ...(noOfEntries ? { noOfEntries } : {}),
    ...(statusIds?.length ? { creativeStatusIds: statusIds } : {}),
    ...(creativeTypeIdsArr?.length ? { platformCreativeTypeIds: creativeTypeIdsArr } : {}),
    ...(creativeIdsArr?.length ? { creativeIds: creativeIdsArr } : {}),
    ...(campaignIds?.length ? { campaignIds } : {}),
    ...(owIds?.length ? { owIds } : {}),
    ...(typeof activeCreatives === 'boolean' ? { activeCreatives } : {}),
    ...(createdAfter ? { createdAfter } : {}),
    ...(typeof allOwIds === 'boolean' ? { allOwIds } : {}),
    ...(typeof includeOrganizationDetails === 'boolean' ? { includeOrganizationDetails } : {}),
    ...(typeof includeUserDetails === 'boolean' ? { includeUserDetails } : {}),
    ...(sortBy?.length ? { sortBy: sortingString } : {}),

    pageNo,
  };
  try {
    const response: AxiosResponse<WithResponse<any>> = await getInstance().post(
      '/v3/crt/creatives/list',
      {
        ...request,
      },
    );

    const creativeList = response?.data?.data?.filteredList.map((data: any) =>
      transformNewCreativeDataToOld(data),
    );
    const retunObject = {
      responseObject: {
        data: creativeList,
        filteredRecords: response?.data?.data?.filteredRecords,
        totalRecords: response?.data?.data?.totalRecords,
      },
      statusCode: response.status,
    };
    return retunObject;
  } catch (e) {
    Logger.log('Error while getting creative list', e);
    return [];
  }
};

export const fetchCreativeListQuery = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCreativeListKey['keys']>>): Promise<any> => {
  const params = queryKey[0]!;
  const {
    pageNo,
    noOfEntries,
    searchField,
    creativeTypeIds,
    statusIds,
    creativeIds,
    sortBy,
    sortType,
    creativeGroupId,
    campaignIds,
    owIds,
    activeCreatives,
    createdAfter,
    allOwIds,
    includeOrganizationDetails,
    includeUserDetails,
  } = params;
  const creativeIdsArr = creativeIds?.split(',').map((id) => Number(id));
  const creativeTypeIdsArr = creativeTypeIds
    ?.split(',')
    .filter((id) => Boolean(id.trim()))
    .map((id) => Number(id));
  const sortingString = sortType === 'desc' ? `-${sortBy}` : `+${sortBy}`;
  const request = {
    ...(typeof creativeGroupId === 'number' ? { creativeGroupId } : {}),
    ...(searchField ? { searchField } : {}),
    ...(noOfEntries ? { noOfEntries } : {}),
    ...(statusIds?.length ? { creativeStatusIds: statusIds } : {}),
    ...(creativeTypeIdsArr?.length ? { platformCreativeTypeIds: creativeTypeIdsArr } : {}),
    ...(creativeIdsArr?.length ? { creativeIds: creativeIdsArr } : {}),
    ...(campaignIds?.length ? { campaignIds } : {}),
    ...(owIds?.length ? { owIds } : {}),
    ...(typeof activeCreatives === 'boolean' ? { activeCreatives } : {}),
    ...(createdAfter ? { createdAfter } : {}),
    ...(typeof allOwIds === 'boolean' ? { allOwIds } : {}),
    ...(typeof includeOrganizationDetails === 'boolean' ? { includeOrganizationDetails } : {}),
    ...(typeof includeUserDetails === 'boolean' ? { includeUserDetails } : {}),
    ...(sortBy?.length ? { sortBy: sortingString } : {}),
    pageNo,
  };
  try {
    const response: AxiosResponse<WithResponse<any>> = await getInstance().post(
      '/v3/crt/creatives/list',
      {
        ...request,
      },
    );
    const creativeList = response?.data?.data?.filteredList.map((data: any) =>
      transformNewCreativeDataToOld(data),
    );
    const retunObject = {
      responseObject: {
        data: creativeList,
        filteredRecords: response?.data?.data?.filteredRecords,
        totalRecords: response?.data?.data?.totalRecords,
      },
      statusCode: response.status,
    };
    return retunObject;
  } catch (e) {
    Logger.log('Error while getting creative list', e);
    return [];
  }
};

export const GetMediaSource = async (id: number): Promise<string> => {
  try {
    const response: AxiosResponse<
      GetResponse<{
        creativeSource: string;
      }>
    > = await getInstance().get(`/v2/crt/creative/${id}`);

    return response.data.responseObject.creativeSource;
  } catch (e) {
    Logger.log('Error while getting media source', e);
    return '';
  }
};

export type TAdPlacement = Response;

export const GetAdPlacementList = async (): Promise<TAdPlacement[]> => {
  try {
    const response: AxiosResponse<TAdPlacement[]> = await getInstance().get(`/video_start_delay`);
    return response.data;
  } catch (e) {
    Logger.log('Error while getting media source', e);
    return [];
  }
};

export type BulkUpdateResponse = {
  message: string;
};

type BulkUpdateClickURLRequestData = {
  creativeIds: string;
  clickURL: string;
  campaignId?: number;
  confirmStatusChange?: boolean;
};

export const BulkUpdateClickURL = async (
  requestData: BulkUpdateClickURLRequestData,
): Promise<WithResponse<BulkUpdateResponse>> => {
  const request = {
    creativeIds: requestData.creativeIds.split(',').map((id) => Number(id)),
    campaignIds: [requestData.campaignId],
    clickUrl: requestData.clickURL,
    confirmStatusChange: requestData.confirmStatusChange,
  };
  try {
    const response: AxiosResponse<WithResponse<BulkUpdateResponse>> = await getInstance().patch(
      '/v3/crt/creatives/click-url',
      request,
    );

    return response.data;
  } catch (e) {
    Logger.log('Error while updating the click URL', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export type BulkUpdatePixelURLRequestData = {
  creativeIds: string;
  pixelURL: string;
};

export interface BulkEditPixelResponse {
  message: string;
}

export const BulkUpdatePixelURL = async (
  requestData: BulkUpdatePixelURLRequestData,
): Promise<WithResponse<BulkEditPixelResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<BulkEditPixelResponse>> = await getInstance().patch(
      '/v3/crt/creatives/pixel-url',
      {
        pixelUrl: requestData.pixelURL,
        creativeIds: requestData.creativeIds.split(',').map((id) => Number(id)),
      },
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while updating the pixel URL', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

type MakeDuplicateCreativeParams = {
  creativeIds: string;
  clickURL: string;
};

export type DuplicateCreativesResponse = {
  duplicatedCreativeIds: number[];
  message: string;
};

export const MakeDuplicateCreatives = async (
  requestData: MakeDuplicateCreativeParams,
): Promise<WithResponse<DuplicateCreativesResponse>> => {
  const request = {
    creativeIds: requestData.creativeIds.split(',').map((id) => Number(id)),
    clickUrl: requestData.clickURL,
  };
  try {
    const res: AxiosResponse<WithResponse<DuplicateCreativesResponse>> = await getInstance().post(
      '/v3/crt/creatives/duplicate',
      request,
    );

    return res.data;
  } catch (e) {
    Logger.log('Error while copying creatives', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};
