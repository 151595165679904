import React from 'react';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import { Icon } from 'factor';

import { Creative } from 'models/Creative';
import { statusIconMap } from 'models/CampaignInfoFields';
import creativeTypeViewByCreativeTypeId, {
  AUDIO_CREATIVE_ID,
  FAILED,
  IMAGE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
} from '../../../constants';
import WithErrorPlaceholderImage from '../../../withErrorPlacehoderImage/WithErrorPlaceholderImage';

import styles from '../CreativeTable.module.scss';

export const HeaderMapper = {
  id: {
    label: 'ID',
    className: 'w-80-80',
    tooltip: 'Unique system-generated number assigned to each Creative',
  },
  creativeSource: {
    label: 'Preview',
    className: 'w-135-135',
    tooltip: 'Creative Preview Thumbnail',
  },
  name: {
    label: 'Name',
    sortingKey: 'name',
    className: styles.creativeNameHeader,
  },
  status: {
    label: 'Status',
    className: 'w-90-90',
    tooltip: 'Creative Status',
  },
  externalCreativeId: {
    label: '3rd Party ID',
    sortingKey: '',
    className: 'w-150-300',
  },
  clickUrl: {
    label: 'Click URL',
    sortingKey: '',
    className: 'w-150-300',
  },
  pixelUrl: {
    label: 'Pixel URL',
    className: 'w-135-135',
  },
  created: {
    label: 'Created',
    sortingKey: 'created',
    className: 'w-135-135',
  },
  size: {
    label: 'Size',
    className: 'w-80-80',
    tooltip: 'Creative dimensions in pixels or the duration of the video or audio ad',
  },
  campaignCount: {
    label: 'Part Of',
    className: 'w-80-80',
  },
};

export const renderContentCell = (creative: Creative) => {
  const { creativeThumbnailSource } = creative;
  if (creativeThumbnailSource) {
    return (
      <WithErrorPlaceholderImage
        src={creative.creativeThumbnailSource}
        className={styles.maxHeight80}
      />
    );
  }
  return (
    <div className={styles.noDataWrapper}>
      <Icon name="NoImage" />
    </div>
  );
};

export const BodyMapper = {
  id: { key: 'id', className: 'w-80-80' },
  creativeSource: {
    className: `w-135-135 ${styles.preview}`,
    key: (creative: Creative) => {
      const { status } = creative;
      if (status === FAILED) {
        return (
          <div className={styles.noDataWrapper}>
            <Icon name="NoImage" />
          </div>
        );
      }
      return <div className={styles.maxHeight80}>{renderContentCell(creative)}</div>;
    },
  },
  name: {
    key: (data: Creative) => {
      return (
        <div className={`${styles.createNameTd}`} title={data.name}>
          <div>
            <Icon name={creativeTypeViewByCreativeTypeId[data.creativeTypeId].iconName} />
          </div>
          <span className={styles.creativeName}>{data.name}</span>
        </div>
      );
    },
    className: styles.creativeNameCol,
    click: (data: Creative) => {
      return data.name;
    },
  },
  status: {
    key: (data: Creative) => {
      return (
        <div className={styles.creativeStatus}>
          <Icon name={statusIconMap[data.status]} />
          {capitalize(data.status)}
        </div>
      );
    },
    className: 'w-90-90',
  },
  externalCreativeId: {
    key: (data: Creative) => {
      return data.externalCreativeId || '—';
    },
    className: `w-150-300 ${styles.textEllipses}`,
  },
  clickUrl: {
    key: (data: Creative) => {
      return data.clickUrl || '—';
    },
    className: `w-150-300 ${styles.textEllipses}`,
  },
  pixelUrl: {
    key: (creative: Creative) => {
      const pixelUrl =
        creative.pixelUrls && creative.pixelUrls.length ? creative.pixelUrls.join(', ') : '—';

      if (creative.creativeTypeId === IMAGE_CREATIVE_ID) {
        return pixelUrl;
      }

      return <div>{pixelUrl}</div>;
    },
    className: `w-135-135 ${styles.textEllipses} ${styles.pixelUrl}`,
  },
  created: {
    key: (data: Creative) => moment(data.created, 'DD MMMM YYYY').format('MM/DD/YYYY'),
    className: 'w-135-135 _right',
  },
  size: {
    className: 'w-80-80 _right',
    key: (data: Creative): string => {
      const { creativeTypeId, duration, width, height } = data;
      switch (creativeTypeId) {
        case VIDEO_CREATIVE_ID:
        case AUDIO_CREATIVE_ID:
          return `${duration}s`;
        case NATIVE_CREATIVE_ID:
          if (Boolean(width) && Boolean(height)) {
            return `${width}x${height}`;
          }
          if (typeof duration === 'number') {
            return `${duration}s`;
          }
          return '—';
        default:
          return `${width}x${height}`;
      }
    },
  },
  campaignCount: { key: 'campaignCount', className: 'w-80-80 _right' },
};
