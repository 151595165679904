import { SortingState } from '@applift/datagrid';
import { deepClone } from '@applift/factor';
import { IO_BUDGET_TYPE_ID } from 'constants/insertionOrder';

export const sortingString = (sorting: SortingState) => {
  return {
    sortBy: sorting.length
      ? sorting
          .map((sort: { desc: boolean; id: string }) => `${sort.desc ? '-' : '+'}${sort.id}`)
          .join(',')
      : undefined,
  };
};

export const getApiCampaignListSorting = (
  sorting: SortingState,
  ioBudgetTypeId?: number,
): SortingState => {
  const dollarBasedToImpressionSortingKeys: { [key: string]: string } = {
    budgetDay: 'dailyImpressions',
    budgetTotal: 'targetImpression',
  };
  let sortingCorrected: SortingState = sorting ? deepClone(sorting) : sorting;

  if (
    sorting &&
    ioBudgetTypeId === IO_BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
    sorting?.find((sort) => dollarBasedToImpressionSortingKeys[sort.id])
  ) {
    sortingCorrected = sortingCorrected.map((sort: any) =>
      sort?.id
        ? {
            ...sort,
            id: dollarBasedToImpressionSortingKeys[sort.id] || sort.id,
          }
        : sort,
    );
  }

  return sortingCorrected;
};

export const getApiIOListSorting = (sorting: SortingState, budgetTypeIds?: string) => {
  const dollarBasedToImpressionSortingKeys: { [key: string]: string } = {
    ioTotalBudget: 'ioTotalImpressions',
  };
  let sortingCorrected = sorting ? deepClone(sorting) : sorting;

  const budgetTypes = budgetTypeIds?.split(',');
  if (
    budgetTypes &&
    budgetTypes.length === 1 &&
    +budgetTypes[0] === IO_BUDGET_TYPE_ID.IMPRESSIONS_BASED
  ) {
    sortingCorrected = sortingCorrected.map((sort: any) =>
      sort?.id ? { ...sort, id: dollarBasedToImpressionSortingKeys[sort.id] || sort.id } : sort,
    );
  }

  return sortingCorrected;
};
