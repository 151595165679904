import * as React from 'react';
import {
  AddAudioCreativeDialog,
  AddImageCreativeDialog,
  AddHTMLCreativeDialog,
  AddVideoCreativeDialog,
  AddNativeCreativeDialog,
} from '@applift/creatives';
import { CREATIVE_ID_FROM_NAME } from 'constants/creatives';
import { ExistingNativeCreationFlowDialog } from './ExistingNativeCreationFlowDialog';

interface AddNewCreativeDialogProps {
  creativeTypeId: number;
  /**
   * only required for the native creative
   */
  isExistingCreative?: boolean;
  onClose: () => void;
  onSuccess?: (response?: any, request?: any) => void;
  onError?: (props: any) => void;
  onSettle?: () => void;
}

export const AddNewCreativeDialog = (props: AddNewCreativeDialogProps) => {
  const { creativeTypeId, onClose, onError, onSettle, onSuccess, isExistingCreative } = props;

  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Image) {
    return (
      <AddImageCreativeDialog
        open
        onClose={onClose}
        actionText="Add to Campaign"
        dialogTitle="Add Image Creative"
        onSuccess={onSuccess}
        onError={onError}
        onSettle={onSettle}
      />
    );
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.HTML) {
    return (
      <AddHTMLCreativeDialog
        open
        onClose={onClose}
        actionText="Add to Campaign"
        dialogTitle="Add HTML Creative"
        onSuccess={onSuccess}
        onError={onError}
        onSettle={onSettle}
      />
    );
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Video) {
    return (
      <AddVideoCreativeDialog
        open
        onClose={onClose}
        actionText="Add to Campaign"
        dialogTitle="Add Video Creative"
        onSuccess={onSuccess}
        onError={onError}
        onSettle={onSettle}
      />
    );
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Audio) {
    return (
      <AddAudioCreativeDialog
        open
        onClose={onClose}
        actionText="Add to Campaign"
        dialogTitle="Add Audio Creative"
        onSuccess={onSuccess}
        onError={onError}
        onSettle={onSettle}
      />
    );
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Native && isExistingCreative === false) {
    return (
      <AddNativeCreativeDialog
        open
        onClose={onClose}
        initData={[]}
        actionText="Add to Campaign"
        dialogTitle="Add Native Creative"
        onSuccess={onSuccess}
        onError={onError}
        onSettle={onSettle}
      />
    );
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Native && isExistingCreative) {
    return (
      <ExistingNativeCreationFlowDialog
        onClose={onClose}
        onSuccess={onSuccess}
        onError={onError}
        onSettle={onSettle}
      />
    );
  }

  return null;
};
