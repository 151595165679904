import { CreativeListParams } from 'models/Creative';
import { scopeTypes } from './ScopeName';

export const getIoListKey = {
  keys: (args: {
    scope: scopeTypes;
    searchField?: string;
    pageSize?: number;
    sortBy?: string;
    ioStatusIds?: string;
    startDate?: Date;
    endDate?: Date;
    timeZoneId?: number;
    budgetTypeIds?: string;
  }) => [args],
};

export const getBasicIoListKey = {
  keys: (args: {
    scope: scopeTypes;
    pageNo?: number;
    noOfEntries?: number;
    owIdList?: number[];
    ioIdList?: number[];
    isAllOwId?: boolean;
    searchField?: string;
    ioStatusIdsList?: number[];
    ioBudgetTypeIdsList?: number[];
    sortBy?: string;
    offset?: number;
  }) => [args],
};

export const getIODetailsQueryKey = {
  keys: (scope: scopeTypes, ioId: string) => [{ scope, ioId } as const],
};

export const getIOBudgetImpInfoQueryKey = {
  keys: (scope: scopeTypes, ioId: string) => [{ scope, ioId } as const],
};

export const getCampaignListKey = {
  keys: (args: {
    scope: scopeTypes;
    ioIds: number[];
    searchField?: string;
    pageSize?: number;
    sortBy?: string;
    timezoneId?: number;
    campaignStatus?: string;
    creativeTypeIds?: string;
    campaignIds?: string;
    startDate?: number;
    endDate?: number;
    campaignTypeIds?: number[];
  }) => [args],
};

export const getBasicCampaignListKey = {
  keys: (args: {
    scope: scopeTypes;
    ioIdsList?: number[];
    ids?: number[];
    responseFields?: string[];
    status?: string[];
    owIds?: number[];
    campaignIds?: number[];
    searchField?: string;
    noOfEntries?: number;
    order?: string;
    sortBy?: string;
    timezoneId?: number;
    campaignStatus?: string;
    campaignTypeIds?: number[];
    creativeTypeIds?: number[];
    pageNo?: number;
  }) => [args],
};

export const getCampaignByStatusCountKey = {
  keys: (scope: scopeTypes, ioId: string, creativeTypeIds?: string) => [
    { scope, ioId, creativeTypeIds } as const,
  ],
};

export const getCampaignByCreativeCountKey = {
  keys: (scope: scopeTypes, ioIds: string, status?: string) => [{ scope, ioIds, status } as const],
};

export const getAudiencesStatusByCampaignIdsKey = {
  keys: (scope: scopeTypes, campaignIds: string) => [{ scope, campaignIds } as const],
};

export const getCreativeListKey = {
  keys: (scope: scopeTypes, creativesListParams: CreativeListParams) => [
    { scope, ...creativesListParams } as const,
  ],
};

export const getAudienceListKey = {
  keys: (
    scope: scopeTypes,
    audienceListParams: {
      searchField: string;
      audienceTypeIds: string;
    },
  ) => [{ scope, audienceListParams } as const],
};

export const getPreBidDataBySegmentIdsKey = {
  keys: (
    scope: scopeTypes,
    segmentListParams: {
      prebidAudienceSegmentIdList: number[];
    },
  ) => [{ scope, segmentListParams } as const],
};

export const getPreBidDataByAudienceIdsKey = {
  keys: (
    scope: scopeTypes,
    segmentListParams: {
      prebidAudienceIdList: number[];
    },
  ) => [{ scope, segmentListParams } as const],
};

export const getIOBudgetTypesKey = {
  keys: (scope: scopeTypes) => [{ scope } as const],
};

export const getCustomerConfigQueryKey = {
  keys: (
    scope: scopeTypes,
    customerConfigParams: {
      owId: number;
    },
  ) => [{ scope, customerConfigParams } as const],
};

export const getModelledListKey = {
  keys: (args: {
    scope: scopeTypes;
    searchField?: string;
    campaignId: string;
    noOfEntries?: number;
    endDate?: string;
    startDate?: string;
    order?: string; // 'DESC' | 'ASC'
    sortBy?: string;
    timeZoneId?: number;
    pageSize?: number;
    pageNo?: number;
    isExcluded?: 0 | 1;
    dimensionId: number;
  }) => [args],
};

export const getModelledDimensionsCountKey = {
  keys: (scope: scopeTypes, campaignId: number, dimensionId?: number) => [
    { scope, campaignId, dimensionId } as const,
  ],
};

export const getPgDealsListKey = {
  keys: (
    scope: scopeTypes,
    search?: string,
    ids?: number[],
    noOfEntries?: number,
    statusIds?: number[],
    campaignId?: number,
    exchangeIds?: number[],
  ) => [{ scope, search, ids, noOfEntries, statusIds, campaignId, exchangeIds } as const],
};

export const getExchangesDetailsList = {
  keys: (scope: scopeTypes) => [{ scope } as const],
};

export const getPgDealsStatusList = {
  keys: (scope: scopeTypes) => [{ scope } as const],
};

export const getPgDealsPaymentTypeList = {
  keys: (scope: scopeTypes) => [{ scope } as const],
};

export const getCampaignData = {
  keys: (scope: scopeTypes, campaignId: number) => [{ scope, campaignId } as const],
};

export const getCountryListQueryKey = {
  keys: (scope: scopeTypes, searchField: string) => [{ scope, searchField } as const],
};

export const getCreativesTypes = {
  keys: (scope: scopeTypes) => [{ scope } as const],
};

export const getPoliticalAdvertiserList = {
  keys: (scope: scopeTypes, owId: number, search?: string) => [{ scope, owId, search } as const],
};

export const getAllowedApplicationsListKey = {
  keys: (scope: scopeTypes) => [{ scope } as const],
};
