import * as React from 'react';
import { connect } from 'react-redux';
import { Creative } from 'models/Creative';
import { DataGrid, getSortedRowModel, useGridApiRef } from '@applift/datagrid';
import { Box, Button, Chip, enqueueSnackbar } from '@applift/factor';
import {
  useCreativeList,
  useDuplicateCreatives,
  useUpdateClickUrl,
  useUpdatePixelUrl,
} from 'hooks/useCreatives';
import { BulkUpdatePixelURLRequestData } from 'api/Creatives';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { CREATIVE_ID_FROM_NAME } from 'constants/creatives';
import { OptionId } from 'models/Option';
import { colDef } from './coldef';
import { EditClickUrlDialog } from './components/EditClickUrlDialog';
import { EditPixelUrlDialog } from './components/EditPixelUrlDialog';
import { ConfirmClickUrlChange } from './components/ConfirmClickUrlChangeDialog';

export interface SelectedCreativedTablePropTypes {
  selectedCreatives: Creative[];
  setSelectedCreatives: (args: Creative[]) => void;
  orgTimezone: OptionId;
}

export const SelectedCreativeTableComponent = (props: SelectedCreativedTablePropTypes) => {
  const { selectedCreatives, setSelectedCreatives, orgTimezone } = props;
  const [rowSelection, setRowSelection] = React.useState({});
  const [showEditClickUrlDialog, setShowEditClickUrlDialog] = React.useState<boolean>(false);
  const [showEditPixelUrlDialog, setShowEditPixelUrlDialog] = React.useState<boolean>(false);
  const [showConfirmClickUrlDialog, setShowConfirmClickUrlDialog] = React.useState<boolean>(false);

  const [tableHeight, setTableHeight] = React.useState<number>(0);

  const apiRef = useGridApiRef();

  const selectedNumberOfRows = Object.keys(rowSelection).length;

  const removeCreativeWrapper = () => {
    // @ts-ignore
    setSelectedCreatives(selectedCreatives.filter((val) => !rowSelection[val.id]));
    setRowSelection({});
  };

  const [lastUpdatedCreativesIds, setLastUpdatedCreativesIds] = React.useState<number[]>();
  const [clickURL, setClickUrl] = React.useState<string>();

  const { refetch: refetchCreative } = useCreativeList(
    {
      creativeIds: lastUpdatedCreativesIds?.length ? lastUpdatedCreativesIds.join(',') : undefined,
    },
    {
      enabled: Boolean(lastUpdatedCreativesIds?.length),
      onSuccess: (res) => {
        const updatedCreatives = res?.responseObject?.data;
        const updateCretivesObj = Object.fromEntries(
          updatedCreatives.map((val: Creative) => [val.id, val]),
        );

        const creativesToPopulate = selectedCreatives.map((val) => {
          if (updateCretivesObj[val.id]) {
            const creative = updateCretivesObj[val.id];
            delete updateCretivesObj[val.id];
            return creative;
          }
          return val;
        });

        const remainingCreatives = updatedCreatives.filter(
          (val: Creative) => updateCretivesObj[val.id],
        );

        const newSelectedCreativeList = [...creativesToPopulate, ...remainingCreatives];
        setSelectedCreatives(newSelectedCreativeList);
        setLastUpdatedCreativesIds([]);
      },
    },
  );

  const selectedRowData = React.useMemo(() => {
    const selectedRowIds = Object.keys(rowSelection).map((id) => Number(id));
    return selectedCreatives.filter((crt) => selectedRowIds.includes(crt.id));
  }, [rowSelection, selectedCreatives]);

  const isDifferentApprovalValueSelected = React.useMemo(() => {
    return (
      selectedRowData.some((crt) => crt.approvalRequiredPostUrlUpdate === true) &&
      selectedRowData.some((crt) => crt.approvalRequiredPostUrlUpdate === false)
    );
  }, [selectedRowData]);

  const isAllSelectedRequireApproval = React.useMemo(() => {
    return selectedRowData.every((crt) => crt.approvalRequiredPostUrlUpdate === true);
  }, [selectedRowData]);

  const isAllSelectedDoesNotRequireApproval = React.useMemo(() => {
    return selectedRowData.every((crt) => crt.approvalRequiredPostUrlUpdate === false);
  }, [selectedRowData]);

  React.useEffect(() => {
    if (lastUpdatedCreativesIds?.length && refetchCreative) {
      refetchCreative();
    }
  }, [lastUpdatedCreativesIds?.length, refetchCreative]);

  const { mutate: clickUrlMutation, isLoading: clickUrlUpdateLoading } = useUpdateClickUrl({
    onSuccess: (response, request: BulkUpdatePixelURLRequestData) => {
      setShowEditClickUrlDialog(false);
      setShowConfirmClickUrlDialog(false);

      if (response.success) {
        const snackbarMessage = response.data?.message
          ? response.data?.message
          : 'Click URL changed successfully for selected creatives.';
        enqueueSnackbar(snackbarMessage, { variant: 'success' });
        setLastUpdatedCreativesIds(request.creativeIds.split(',').map((id) => Number(id)));
      }
    },
    onError: (res) => {
      enqueueSnackbar(res?.errorMsg, { variant: 'error' });
    },
  });

  const { mutate: duplicateCreativesMutation, isLoading: duplicateCreativesLoading } =
    useDuplicateCreatives({
      onSuccess: (response) => {
        setShowEditClickUrlDialog(false);
        setShowConfirmClickUrlDialog(false);
        const snackbarMessage = response.data?.duplicatedCreativeIds.length
          ? 'Creatives duplicated successfully.'
          : 'Something went wrong';

        if (response?.data?.duplicatedCreativeIds) {
          setLastUpdatedCreativesIds(response?.data?.duplicatedCreativeIds);
        }
        const snackbarVariant = response.data?.duplicatedCreativeIds.length ? 'success' : 'error';
        enqueueSnackbar(snackbarMessage, { variant: snackbarVariant });
      },
    });

  const { mutate: pixelUrlMutation, isLoading: pixelUrlUpdateLoading } = useUpdatePixelUrl({
    onSuccess: (response: any, request: BulkUpdatePixelURLRequestData) => {
      setShowEditPixelUrlDialog(false);
      const snackbarMessage = response.success
        ? response?.data?.message || 'Pixel URL changed successfully for selected creatives.'
        : 'Something went wrong';

      const snackbarVariant = response.success ? 'success' : 'error';
      enqueueSnackbar(snackbarMessage, { variant: snackbarVariant });
      setLastUpdatedCreativesIds(request?.creativeIds.split(',').map((id) => Number(id)));
    },
    onError: (res) => {
      enqueueSnackbar(res?.errorMsg ?? 'Something went wrong.', { variant: 'error' });
    },
  });

  const duplicateCreatives = React.useCallback(
    (clickURL, creativeIds) => {
      duplicateCreativesMutation({ clickURL, creativeIds: creativeIds?.join(',') });
    },
    [duplicateCreativesMutation],
  );

  const updateClickUrl = React.useCallback(
    (clickUrl: string, confirmStatusChange?: boolean) => {
      clickUrlMutation({
        clickURL: clickUrl,
        confirmStatusChange,
        creativeIds: selectedCreatives
          // @ts-ignore
          .filter((val) => rowSelection[val.id])
          .map((val) => val.id)
          .join(','),
      });
    },
    [clickUrlMutation, rowSelection, selectedCreatives],
  );

  const updatePixelUrl = React.useCallback(
    (pixelUrl: string) => {
      pixelUrlMutation({
        pixelURL: pixelUrl,
        creativeIds: selectedCreatives
          // @ts-ignore
          .filter((val) => rowSelection[val.id])
          .map((val) => val.id)
          .join(','),
      });
    },
    [pixelUrlMutation, rowSelection, selectedCreatives],
  );

  const disablePixelUrl = React.useMemo(() => {
    const selectedCreativeWithDetails = selectedCreatives.filter(
      // @ts-ignore
      (val) => rowSelection[val.id as string],
    );
    return selectedCreativeWithDetails.every(
      (val) => val.creativeTypeId !== CREATIVE_ID_FROM_NAME.Image,
    );
  }, [rowSelection, selectedCreatives]);

  React.useEffect(() => {
    setTableHeight(selectedCreatives.length * 80 + 74);
  }, [selectedCreatives.length]);

  return (
    <Box
      style={{ height: `${tableHeight}px`, maxHeight: '420px' }}
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Box sx={{ mb: 16, display: 'flex', gapCol: 16 }}>
        {selectedNumberOfRows ? (
          <>
            <Chip
              size="small"
              color="secondary"
              label={`${selectedNumberOfRows} Creative(s) Selected`}
              onDelete={() => setRowSelection({})}
            />
            {isDifferentApprovalValueSelected === false && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => setShowEditClickUrlDialog(true)}
              >
                Edit Click URL
              </Button>
            )}
            {disablePixelUrl ? null : (
              <Button
                variant="outlined"
                size="small"
                onClick={() => setShowEditPixelUrlDialog(true)}
              >
                Edit Pixel URL
              </Button>
            )}
            <Button onClick={removeCreativeWrapper} variant="outlined" size="small" color="error">
              Remove
            </Button>
          </>
        ) : null}
      </Box>
      <DataGrid
        apiRef={apiRef}
        rowHeight={80}
        checkboxSelection
        data={selectedCreatives}
        columns={colDef}
        meta={{
          orgTimezone,
        }}
        disableColumnReorder
        getRowId={(row) => {
          return String(row?.id);
        }}
        state={{ rowSelection }}
        getSortedRowModel={getSortedRowModel()}
        hideFooter
        hideHeader
        showCellRightBorder
        showColumnRightBorder
        disableRowSelectionOnClick
        onRowSelectionChange={setRowSelection}
        headerHeight={40}
      />
      {showEditClickUrlDialog && (
        <EditClickUrlDialog
          creativeIds={[]}
          setShowEditClickUrlDialog={setShowEditClickUrlDialog}
          updateClickUrl={(clickURL) => {
            if (isAllSelectedDoesNotRequireApproval) {
              updateClickUrl(clickURL);
            }
            if (isAllSelectedRequireApproval) {
              setShowConfirmClickUrlDialog(true);
              // closing current dialog
              setShowEditClickUrlDialog(false);
            }
          }}
          clickUrlUpdateLoading={clickUrlUpdateLoading}
          clickUrl={clickURL}
          setClickUrl={setClickUrl}
        />
      )}
      {showEditPixelUrlDialog && (
        <EditPixelUrlDialog
          creativeIds={[]}
          setShowEditPixelUrlDialog={setShowEditPixelUrlDialog}
          updatePixelUrl={updatePixelUrl}
          pixelUrlUpdateLoading={pixelUrlUpdateLoading}
        />
      )}
      {showConfirmClickUrlDialog && (
        <ConfirmClickUrlChange
          creativeIds={
            Object.keys(rowSelection).length
              ? // @ts-ignore
                selectedCreatives.filter((val) => rowSelection[val.id]).map((val) => val.id)
              : []
          }
          failedCreatives={selectedRowData}
          setShowConfirmClickUrlDialog={setShowConfirmClickUrlDialog}
          updateClickUrl={updateClickUrl}
          clickUrl={clickURL}
          clickUrlUpdateLoading={clickUrlUpdateLoading}
          duplicateCreatives={duplicateCreatives}
          duplicateCreativesLoading={duplicateCreativesLoading}
        />
      )}
    </Box>
  );
};

const mapState = (state: AppState) => ({
  orgTimezone: state.advanced.sidebarCampaignInfo[CampaignInfoField.timezone],
});

export const SelectedCreativeTable = connect(mapState)(SelectedCreativeTableComponent);
