import * as React from 'react';
import {
  Button,
  Dialog,
  Typography,
  DialogTitle,
  LoadingButton,
  DialogActions,
  DialogContent,
} from '@applift/factor';

import { SelectCreativeSection } from '@applift/creatives';
import { transformNewCreativeDataToOld } from 'utils/creatives';
import { Creative } from 'models/Creative';
import { getCreativeIcon } from 'constants/iconMapper';
import { CREATIVE_NAME_FROM_ID } from 'constants/creatives';

interface SelectCreativeDialogProps {
  creativeTypeId: number;
  onSuccess: (creatives: Creative[]) => void;
  onClose: () => void;
}

export const SelectCreativeDialog = (props: SelectCreativeDialogProps) => {
  const { onClose, creativeTypeId, onSuccess } = props;

  const [selectedCreatives, setSelectedCreatives] = React.useState<any[]>([]);

  const getIconName = () => {
    return CREATIVE_NAME_FROM_ID[creativeTypeId as keyof typeof CREATIVE_NAME_FROM_ID] || '';
  };

  return (
    <Dialog open maxWidth="xl" fullWidth sx={{ height: 100 }} PaperProps={{ sx: { height: 100 } }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 4 }} onClose={onClose}>
        {getCreativeIcon(getIconName(), { fontSize: 32 })}
        <Typography weight="demi">{`Select ${getIconName()} Creatives`}</Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ py: 0, px: 0 }}>
        <SelectCreativeSection
          creativeTypeId={creativeTypeId}
          onChange={(creative) => {
            setSelectedCreatives(creative);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={selectedCreatives.length <= 0}
          onClick={() => {
            const transformedCreatives = selectedCreatives.map((creative) =>
              transformNewCreativeDataToOld(creative),
            );
            onSuccess(transformedCreatives ?? []);
            onClose();
          }}
        >
          Select
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
