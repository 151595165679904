import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
/* @ts-ignore */
import { initDataDog, useAutoLogin, ORIGIN_URL } from 'iqm-framework';

import { logWarningXSS } from './utils/logger';
import { App } from './App/App';
import { store } from './store';
import * as serviceWorker from './serviceWorker';
import { isProd } from './config';

import './App/App.scss';
import '@applift/factor/dist/style';
import '@applift/datagrid/dist/style';
import '@applift/platform/dist/style';
import '@applift/platform-creatives/dist/style';
import '@applift/bid-model/lib/style';
import '@applift/creatives/lib/style';

// import '@applift/inventory/lib/style';

if (isProd) {
  logWarningXSS();
  initDataDog('campaigns', 'prod', {
    allowedTracingOrigins: [ORIGIN_URL],
    trackViewsManually: true,
  });
} else {
  initDataDog('campaigns', 'stage', {
    allowedTracingOrigins: [ORIGIN_URL],
    trackViewsManually: true,
  });
}

const AppWrapper = () => {
  const [autoLoginInProcess, message] = useAutoLogin();
  return <Provider store={store}>{autoLoginInProcess ? message : <App />}</Provider>;
};

ReactDOM.render(<AppWrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
