import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { connect } from 'react-redux';
import { Typography } from '@applift/factor';
import { createSelector } from 'reselect';
import classNames from 'classnames/bind';
import { Tabs, Snackbar } from 'factor';
import { Option } from 'models/Option';
import { AppState } from 'models/Store';
import { LocationBlockState, ExistingCampaignData } from 'models/ExistingCampaign';
import { ICountryOption, IStateOption } from 'models/Location';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ErrorCreatingResponse } from 'models/Response';
import { errorFieldsMapper } from 'constants/errorFieldsMapper';
import {
  advanceActions,
  SetBlackList,
  SetCampaignSidebarInfo,
  SetWhiteList,
} from 'store/advance/actions';
import { ResetError, applicationActions } from 'store/app/actions';
import { blackListNameByTagName, US_COUNTRY_ID, whiteListNameByTagName } from 'constants/location';
import { BlackLists, WhiteLists } from 'store/advance/reducer';

import { plannerCollectRequest } from 'utils/transformLocationData';
import { mapActions, SetAreaType, SetDrawerMode, SetToastMessage } from 'store/map/actions';
import { ListedTagsNames, Tags } from '../../MainPage/CampaignPage/LocationBlock/Tags';
import { CountrySelectV2Wrapper } from '../../MainPage/CampaignPage/LocationBlock/CountrySelectV2Wrapper';
import { Map } from '../../MainPage/CampaignPage/LocationBlock/Map';
import { EditableCampaignMarkers } from '../../MainPage/CampaignPage/LocationBlock/EditableCampaignMarkers';
import { UploadZipCodeWrapper } from '../../MainPage/CampaignPage/LocationBlock/UploadZipCodeWrapper';
import { LocationWrapper } from '../../MainPage/CampaignPage/LocationBlock/LocationWrapper';
import {
  getLocationAPIList,
  isLocationSelectionPresent,
} from '../../MainPage/CampaignPage/helpers';
import styles from './LocationBlock.module.scss';

const cx = classNames.bind(styles);

interface Props
  extends SetCampaignSidebarInfo,
    ResetError,
    SetWhiteList,
    SetBlackList,
    SetToastMessage,
    SetAreaType,
    SetDrawerMode {
  country: ICountryOption | null;
  selectedStates: IStateOption[];
  errorCreating: ErrorCreatingResponse | null;
  editableCampaign: ExistingCampaignData | null;
  isOpenMapVisible: boolean;
  whiteLists: WhiteLists;
  blackLists: BlackLists;
  locationBlockState: LocationBlockState;
}

const tabItems = (isUSA = true, showPolitical = false) => {
  const returnArr = [
    {
      title: 'Location',
      value: 'location',
    },
    {
      title: `${isUSA ? 'Zip' : 'Postal'} Codes`,
      value: 'zipCodes',
    },
  ];

  if (isUSA && showPolitical) {
    returnArr.push({ title: 'Political Targeting', value: 'politicalTargeting' });
  }

  return returnArr;
};

const LocationBlockComponent = forwardRef<any, Props>((props, ref) => {
  const {
    setCampaignSidebarInfo,
    country,
    selectedStates,
    errorCreating,
    editableCampaign,
    isOpenMapVisible,
    whiteLists,
    blackLists,
    resetError,
    setWhiteList,
    setBlackList,
    locationBlockState,
    setToastMessage,
    setDrawerMode,
    setAreaType,
  } = props;

  const [extensionError, setExtensionError] = useState<string | JSX.Element>('');
  const [selectedTab, setSelectedTab] = useState<string>('location');
  // const [addressesSelection, setAddressesSelection] = useState<TLocationFileType>('locations');
  const isMounted = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const getLocationDataForSave = () => {
    const { isEmpty, isOnlyStateSelected, ...others } = plannerCollectRequest(locationBlockState);
    // if (isOnlyStateSelected) {
    //   setSnackbarMessage('Please select atleast one additional filter along with state');
    //   others.hasError = true;
    // } else {
    //   setSnackbarMessage('');
    // }
    return others;
  };
  useEffect(() => {
    setDrawerMode(null as any);
    setAreaType(null);
  }, [setDrawerMode, setAreaType]);

  useImperativeHandle(ref, () => ({
    getLocationDataForSave,
  }));

  const saveToStoreString = useCallback(
    (field: keyof typeof CampaignInfoField) => (value: string | Option[]) => {
      setCampaignSidebarInfo(CampaignInfoField[field], value);
      if (Object.keys(whiteListNameByTagName).includes(field) && whiteLists) {
        const whiteListName = whiteListNameByTagName[field as ListedTagsNames];
        const blackListName = blackListNameByTagName[field as ListedTagsNames];
        const whiteList = whiteLists[whiteListName] || [];
        const blackList = blackLists[blackListName] || [];

        if (typeof value === 'string' || typeof value === 'number') {
          setWhiteList(whiteListName, new Set([...whiteList, String(value) as any]));
        } else {
          const newValues = value
            .filter((item) => !blackList.includes(String(item.value) as any))
            .map(({ value }) => String(value));
          setWhiteList(whiteListName, new Set(newValues) as any);
        }
      }
      // We want to ensure to remove any id from blackList if it is not availalbe in the values.
      if (Object.keys(blackListNameByTagName).includes(field) && blackLists) {
        const blackListName = blackListNameByTagName[field as ListedTagsNames];
        const blackList = blackLists[blackListName] || [];
        if (typeof value === 'string' || typeof value === 'number') {
          setBlackList(blackListName, new Set([...blackList, String(value) as any]));
        } else {
          const newBlackList = blackList.filter(
            (item) => value.findIndex((one) => (String(one.value) as any) === item) >= 0,
          );
          setBlackList(blackListName, new Set(newBlackList));
        }
      }
      if (errorCreating && errorCreating.errorField === errorFieldsMapper[field]) {
        resetError();
      }
    },
    [
      errorCreating,
      setCampaignSidebarInfo,
      resetError,
      setWhiteList,
      setBlackList,
      whiteLists,
      blackLists,
    ],
  );
  const saveToStore = useCallback(
    (field: keyof typeof CampaignInfoField) => (value: string | Option[]) => {
      setCampaignSidebarInfo(CampaignInfoField[field], value);
      if (Object.keys(whiteListNameByTagName).includes(field) && whiteLists) {
        const whiteListName = whiteListNameByTagName[field as ListedTagsNames];
        const blackListName = blackListNameByTagName[field as ListedTagsNames];
        const whiteList = whiteLists[whiteListName] || [];
        const blackList = blackLists[blackListName] || [];

        if (typeof value === 'string' || typeof value === 'number') {
          setWhiteList(whiteListName, new Set([...whiteList, Number(value)]));
        } else {
          const newValues = value
            .filter((item) => !blackList.includes(Number(item.value)))
            .map(({ value }) => Number(value));
          setWhiteList(whiteListName, new Set(newValues));
        }
      }
      // We want to ensure to remove any id from blackList if it is not availalbe in the values.
      if (Object.keys(blackListNameByTagName).includes(field) && blackLists) {
        const blackListName = blackListNameByTagName[field as ListedTagsNames];
        const blackList = blackLists[blackListName] || [];
        if (typeof value === 'string' || typeof value === 'number') {
          setBlackList(blackListName, new Set([...blackList, Number(value)]));
        } else {
          const newBlackList = blackList.filter(
            (item) => value.findIndex((one) => Number(one.value) === item) >= 0,
          );
          setBlackList(blackListName, new Set(newBlackList));
        }
      }
      if (errorCreating && errorCreating.errorField === errorFieldsMapper[field]) {
        resetError();
      }
    },
    [
      errorCreating,
      setCampaignSidebarInfo,
      resetError,
      setWhiteList,
      setBlackList,
      whiteLists,
      blackLists,
    ],
  );

  const isSelectedCountryUSA = useCallback(() => {
    return !!(country && country.value === US_COUNTRY_ID);
  }, [country]);

  const handleTabChange = useCallback(
    (item: { value: string }) => {
      setSelectedTab(item.value);
      if (selectedTab !== item.value) {
        setExtensionError('');
      }
    },
    [selectedTab],
  );

  useEffect(() => {
    if (window.top) {
      window.top.postMessage('update', '*');
    }
  }, [whiteLists, blackLists]);

  useEffect(() => {
    if (!selectedStates.length && selectedTab === 'politicalTargeting') {
      handleTabChange({ value: 'customArea' });
    }
    if (selectedStates.length > 5 && selectedTab === 'politicalTargeting') {
      handleTabChange({ value: 'customArea' });
    }
  }, [selectedTab, selectedStates, handleTabChange]);

  useEffect(() => {
    if (editableCampaign && !isMounted.current) {
      if (editableCampaign.filterStore?.country) {
        setCampaignSidebarInfo(CampaignInfoField.country, editableCampaign.filterStore.country);
      }
      if (isLocationSelectionPresent(editableCampaign)) {
        try {
          const apiList = getLocationAPIList(
            editableCampaign,
            editableCampaign?.parentApp?.toLowerCase() as string,
          );
          Promise.allSettled(apiList.map((field) => field.api)).then((results) => {
            results.forEach((result, idx) => {
              if (result.status === 'fulfilled' && result.value.length) {
                setCampaignSidebarInfo(apiList[idx].field, result.value);
              }
            });
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
      isMounted.current = true;
    }
  }, [editableCampaign, saveToStoreString, setCampaignSidebarInfo]);

  useEffect(() => {
    document.body.style.overflow = isOpenMapVisible ? 'hidden' : '';
  }, [isOpenMapVisible]);

  return (
    <div className={styles.locationsWrapper}>
      <div className={styles.campaignsLocationContainer}>
        <div className={styles.topContainer}>
          <div className="row w-100">
            <div style={{ display: 'none' }}>
              <CountrySelectV2Wrapper />
            </div>
            <Typography sx={{ textColor: 'neutral-600', ml: 16 }}>
              Country:
              <Typography weight="demi" sx={{ textColor: 'neutral-1000', ml: 2 }}>
                United States
              </Typography>
            </Typography>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.tabsWrapper}>
              <Tabs items={tabItems(true, false)} value={selectedTab} onChange={handleTabChange} />
              <div
                className={`${styles.tabContent} ${
                  selectedTab === 'location' ? styles.show : 'hidden'
                }`}
              >
                <div style={{ width: '100%' }}>
                  <LocationWrapper
                    saveLocations={saveToStore}
                    country={country}
                    isOpenMapsVisible={isOpenMapVisible}
                    setToastMessage={setToastMessage}
                    selectedTab={selectedTab}
                    parent="planner"
                  />
                </div>
              </div>
              <div
                className={`${styles.tabContent} ${
                  selectedTab === 'zipCodes' ? styles.show : 'hidden'
                }`}
              >
                <div className={`${styles.tabContent}`}>
                  <UploadZipCodeWrapper
                    styles={styles}
                    extensionError={extensionError}
                    setExtensionError={setExtensionError}
                    isSelectedCountryUSA={isSelectedCountryUSA}
                    changed={saveToStoreString('zipcodes')}
                  />
                </div>
              </div>
            </div>
            <div
              className={cx({
                map_container: true,
                map_container__visible: isOpenMapVisible,
                map_container__visible__wide: isOpenMapVisible,
              })}
            >
              <Map />
            </div>
          </div>
          <div className={styles.rightContainer} ref={containerRef}>
            <EditableCampaignMarkers />
            <Tags hideToggle containerRef={containerRef.current ?? undefined} />
          </div>
        </div>
      </div>
      <Snackbar
        className="geo-restriction"
        open={Boolean(snackbarMessage)}
        message={snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        showCloseButton
      />
    </div>
  );
});

const mapState = createSelector(
  (state: AppState) => state.advanced.sidebarCampaignInfo,
  (state: AppState) => state.app,
  (state: AppState) => state.map,
  (state: AppState) => state.advanced.whiteLists,
  (state: AppState) => state.advanced.filterStore,
  (state: AppState) => state.advanced.blackLists,
  (state: AppState) => ({ advanced: state.advanced, map: state.map, location: state.location }),
  (
    sidebarCampaignInfo,
    appStore,
    mapStore,
    whiteLists,
    filterStore,
    blackLists,
    locationBlockState,
  ) => ({
    country: sidebarCampaignInfo[CampaignInfoField.country],
    selectedStates: sidebarCampaignInfo[CampaignInfoField.states],
    dma: sidebarCampaignInfo[CampaignInfoField.dma],
    locationBlockState,
    errorCreating: appStore.errorCreating,
    editableCampaign: appStore.editableCampaign,
    isOpenMapVisible: mapStore.isOpenMapVisible,
    whiteLists,
    blackLists,
    filterStore,
  }),
);

const actions = {
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  resetError: applicationActions.resetError,
  setWhiteList: advanceActions.setWhiteList,
  setBlackList: advanceActions.setBlackList,
  setToastMessage: mapActions.setToastMessage,
  setDrawerMode: mapActions.setDrawerMode,
  setAreaType: mapActions.setAreaType,
};

export const LocationBlock = connect(mapState, actions, null, { forwardRef: true })(
  LocationBlockComponent,
);
