import { AddNativeCreativeDialog, ExisitingNativeCreativeDialog } from '@applift/creatives';
import * as React from 'react';

interface ExistingNativeCreationFlowDialogProps {
  onClose: () => void;
  onSuccess?: (response?: any, request?: any) => void;
  onError?: (props: any) => void;
  onSettle?: () => void;
}

export const ExistingNativeCreationFlowDialog = (props: ExistingNativeCreationFlowDialogProps) => {
  const { onClose, onError, onSettle, onSuccess } = props;
  const [step, setStep] = React.useState(1);
  const [nativeGenerationData, setNativeGenerationData] = React.useState<any[]>([]);

  return (
    <>
      {step === 1 && (
        <ExisitingNativeCreativeDialog
          open
          onClose={onClose}
          dialogTitle="Add Native Creative"
          onAction={(nativeData) => {
            setNativeGenerationData(nativeData);
            setStep(2);
          }}
        />
      )}
      {step === 2 && (
        <AddNativeCreativeDialog
          open
          onClose={onClose}
          initData={nativeGenerationData}
          actionText="Add to Campaign"
          dialogTitle="Add Native Creative"
          onSuccess={onSuccess}
          onError={onError}
          onSettle={onSettle}
        />
      )}
    </>
  );
};
