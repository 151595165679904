import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { CampaignCreateUpdateParams, GeoRadiusData } from 'api/Campaign';
import { Option, NestedOption, ExcludedOption } from 'models/Option';
import { getListedAudienceEstimatorInfo, getListedAudienceIds } from 'utils/audience';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { compareNumberStrings, validateUrl } from 'utils/validationRules';
import { AppState } from 'models/Store';
import { AdvertiserState } from 'store/advertiser/reducer';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { AUDIO_CREATIVE_ID, VIDEO_CREATIVE_ID } from 'models/Creative';
import { CreativesState } from 'store/creatives/reducer';
import { PublishersState } from 'store/publishers/reducer';
import { TechnologyStore } from 'store/technology/reducer';
import { BidStore } from 'store/bid/reducer';
import { LocationState } from 'store/location/reducer';
import { errorFieldsMapper } from 'constants/errorFieldsMapper';
import { ErrorCreatingResponse } from 'models/Response';
import { CircleExtended } from 'models/CircleExtended';
import { metersToMiles } from 'utils/format';
import { PolygonExtended, RadiusUnity } from 'models/PolygonExtended';
import { RectangleExtended } from 'models/RectangleExtended';
import { CampaignEstimatorParams } from 'models/Estimator';
import { BUDGET_TYPE_ID } from 'constants/apps';

export const checkBudgets = (
  total: number,
  bidValue: number,
  daily?: number | false,
  baseBid?: number,
) => {
  if (!total || !bidValue || total < bidValue || bidValue < (baseBid ?? 0)) {
    return false;
  }
  return daily ? total >= daily : total >= bidValue;
};

export const checkSaveDraftBudgets = (total: number, bidValue: number, daily?: number | false) => {
  if (!total && !bidValue && !daily) {
    return true;
  }
  if (!total || !bidValue) {
    return false;
  }
  return daily ? total >= daily : total >= bidValue;
};

export const checkBudgetCapping = (isBudgetCappingOn: boolean, total: any, daily: any) => {
  const { impressionsMaxTotal, clicksMaxTotal, conversionsMaxTotal } = total;
  const { impressionsMaxDaily, clicksMaxDaily, conversionsMaxDaily } = daily;

  if (!isBudgetCappingOn) {
    return true;
  }

  const validImpressions =
    !+impressionsMaxTotal || !+impressionsMaxDaily || +impressionsMaxTotal >= +impressionsMaxDaily;

  const validTotal = !+clicksMaxTotal || !+clicksMaxDaily || +clicksMaxTotal >= +clicksMaxDaily;

  const validConversations =
    !+conversionsMaxTotal || !+conversionsMaxDaily || +conversionsMaxTotal >= +conversionsMaxDaily;

  return validImpressions && validTotal && validConversations;
};

export const checkAdvertiser = (advertiser: AdvertiserState): boolean => {
  if (
    (advertiser.isPoliticalAdvertising && advertiser.politicalAdvertiser) ||
    !advertiser.isPoliticalAdvertising
  ) {
    return true;
  }
  return false;
};

export const isCreativeDataValid = (state: CreativesState): boolean => {
  return !!(
    state.selectedCreatives.length &&
    state.advertiserUrl.length &&
    validateUrl(true).func(state.advertiserUrl)
  );
};

export const isTechnologyDataValid = (state: TechnologyStore): boolean => {
  return !!(
    state.sidebarCampaignInfo[CampaignInfoField.deviceTypes].length &&
    state.sidebarCampaignInfo[CampaignInfoField.traffic].length
  );
};

export const isPublisherDataValid = (state: PublishersState): boolean => {
  return state.selectedPublishers.length !== 0;
};

export const isExchangesDataValid = (selectedExchanges: Option<number>[]): boolean => {
  return Boolean(Array.isArray(selectedExchanges) && selectedExchanges.length > 0);
};

export const isBidDataValid = (state: BidStore): boolean => {
  return checkBudgetCapping(
    state.budgetCapping,
    {
      impressionsMaxTotal: state.impressionsMaxTotal,
      clicksMaxTotal: state.clicksMaxTotal,
      conversionsMaxTotal: state.conversionsMaxTotal,
    },
    {
      impressionsMaxDaily: state.impressionsMaxDaily,
      clicksMaxDaily: state.clicksMaxDaily,
      conversionsMaxDaily: state.conversionsMaxDaily,
    },
  );
};

export const isLocationDataValid = (state: LocationState): boolean => {
  return state.locationBlockErrors.length === 0;
};

export const getTechnologyBlockErrorCreating = (
  errorCreating: ErrorCreatingResponse | null,
): boolean => {
  return (
    !!errorCreating &&
    [errorFieldsMapper[CampaignInfoField.deviceTypes]].includes(errorCreating.errorField)
  );
};

export const getInventoryBlockErrorCreating = (
  errorCreating: ErrorCreatingResponse | null,
): boolean => {
  return (
    !!errorCreating &&
    [
      errorFieldsMapper.selectedPublishers,
      errorFieldsMapper.whiteListedInventoryGroupIds,
      errorFieldsMapper.blackListedInventoryGroupIds,
    ].includes(errorCreating.errorField)
  );
};

export function checkValidation(state: AppState) {
  const {
    advanced: advanceState,
    technology,
    publishers,
    creatives,
    bid,
    app: applicationState,
    advertiser,
    advancedTargeting,
  } = state;

  const validateTime = (): boolean => {
    const start = advanceState.sidebarCampaignInfo[CampaignInfoField.start];
    const end = advanceState.sidebarCampaignInfo[CampaignInfoField.end];
    const timezone = advanceState.sidebarCampaignInfo[CampaignInfoField.timezone];
    const { isDateDefault } = advanceState;
    const { isEditingMode } = applicationState;

    if (isEditingMode && isDateDefault) {
      return !!(
        (!advanceState.budgetPacing || end) &&
        (end ? moment(end).diff(start, 'minute') >= 15 : true)
      );
    }

    if (timezone) {
      return !!(
        moment().valueOf() < moment(start).add(15, 'minute').valueOf() &&
        (!advanceState.budgetPacing || end) &&
        (end ? moment(end).diff(start, 'minute') >= 15 : true)
      );
    }

    return false;
  };

  const checkDailyBudget = () => {
    if (applicationState.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
      return advanceState.budgetPacing || bid.impressionsMaxDaily;
    }
    return (
      advanceState.budgetPacing ||
      Number(advanceState.sidebarCampaignInfo[CampaignInfoField.dailyBudget])
    );
  };

  return !!(
    isTechnologyDataValid(technology) &&
    advanceState.sidebarCampaignInfo[CampaignInfoField.name].trim() &&
    isPublisherDataValid(publishers) &&
    isExchangesDataValid(advancedTargeting.sidebarCampaignInfo.selectedExchanges) &&
    isCreativeDataValid(creatives) &&
    isLocationDataValid(state.location) &&
    validateTime() &&
    advanceState.sidebarCampaignInfo[CampaignInfoField.totalBudget] &&
    advanceState.sidebarCampaignInfo[CampaignInfoField.maxBid] &&
    checkDailyBudget() &&
    checkBudgets(
      +advanceState.sidebarCampaignInfo[CampaignInfoField.totalBudget],
      +advanceState.sidebarCampaignInfo[CampaignInfoField.maxBid],
      !advanceState.budgetPacing &&
        +advanceState.sidebarCampaignInfo[CampaignInfoField.dailyBudget],
      +advanceState.sidebarCampaignInfo[CampaignInfoField.baseBid],
    ) &&
    isBidDataValid(bid) &&
    checkAdvertiser(advertiser)
  );
}

export function checkSaveDraftValidation(state: AppState) {
  const { advanced: advanceState } = state;

  const maxBid = +advanceState.sidebarCampaignInfo[CampaignInfoField.maxBid];
  const totalBudget = +advanceState.sidebarCampaignInfo[CampaignInfoField.totalBudget];
  const { selectedExchanges } = state.advancedTargeting.sidebarCampaignInfo;

  return (
    !!advanceState.sidebarCampaignInfo[CampaignInfoField.name].trim() &&
    (!maxBid || !totalBudget || maxBid <= totalBudget) &&
    isExchangesDataValid(selectedExchanges)
  );
}

export function extractPartialRequestForLogs(state: AppState): Partial<CampaignCreateUpdateParams> {
  const { isBidShadingApplicable } = state.advertiser;
  const { sidebarCampaignInfo } = state.advanced;
  const { budgetTypeId } = state.app;
  let request: Partial<CampaignCreateUpdateParams> = {
    campaignName: sidebarCampaignInfo.campaignName,
    maxBid: +sidebarCampaignInfo.maxBid,
    ...(budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
      ? { budgetTotal: +sidebarCampaignInfo.totalBudget }
      : {}),
    isBidShading: isBidShadingApplicable ? sidebarCampaignInfo.isBidShading : undefined,
    startTime: Math.round(sidebarCampaignInfo.startDate / 1000),
    endTime: sidebarCampaignInfo.endDate
      ? Math.round(sidebarCampaignInfo.endDate / 1000)
      : sidebarCampaignInfo.endDate || 0,
    timezone: sidebarCampaignInfo?.timezone?.id || undefined,
  };

  if (!state.advanced.budgetPacing) {
    request = {
      ...request,
      ...(budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? { budgetDay: +sidebarCampaignInfo.dailyBudget }
        : {}),
    };
  }

  if (state.creatives.selectedCreativeType) {
    request = {
      ...request,
      creativeType: state.creatives.selectedCreativeType.value,
    };
  }

  return request;
}

export function collectRequest(
  state: AppState,
  ignoreCountry?: boolean,
): CampaignCreateUpdateParams | null {
  const {
    technology,
    bid,
    publishers,
    advertiser,
    demographic,
    inventory,
    scheduling,
    advancedTargeting,
    audience,
    conversion,
    location,
    advanced,
    map,
    app,
    estimator,
  } = state;

  const { sidebarCampaignInfo, whiteLists, blackLists } = advanced;
  const { locationFiles } = location;

  const allPublishers = state.publishers.publishers.reduce(
    (acc: Option<number>[], group: NestedOption): Option<number>[] => {
      if (group.options) {
        acc.push(...group.options);
      }
      acc.push({
        label: group.label,
        value: group.value,
      });
      return acc;
    },
    [],
  );

  const getListedGeoRadiusDetails = (
    isWhite: boolean,
    circles: CircleExtended[],
    polygons: PolygonExtended[],
    rectangles: RectangleExtended[],
  ): GeoRadiusData[] => {
    const formatedCircles = circles
      .filter((circle) => !circle.filename && circle.isInclude === isWhite)
      .map((circle) => ({
        unit: 'mile',
        address: circle.address,
        latitude: circle.lat,
        radius: metersToMiles(circle.getRadius()),
        type: circle.type,
        longitude: circle.lng,
        sid: circle.state,
        cid: circle.country,
      }));

    const formatedPolygons = polygons
      .filter((polygon) => !polygon.fileId && !polygon.filename && polygon.isInclude === isWhite)
      .map((polygon) => ({
        polypath: polygon.getPolyPath(),
        unit: 'mile',
        address: polygon.address,
        latitude: polygon.lat,
        type: polygon.type,
        radius: polygon.getRadius(RadiusUnity.mile),
        longitude: polygon.lng,
        sid: polygon.state,
        cid: polygon.country,
      }));

    const formatedRectangles = rectangles
      .filter((rectangle) => rectangle.isInclude === isWhite)
      .map((rectangle) => ({
        polypath: rectangle.getPolyPath(),
        unit: 'mile',
        address: rectangle.address,
        type: rectangle.type,
        radius: rectangle.getRadius(RadiusUnity.mile),
        sid: rectangle.state,
        cid: rectangle.country,
        longitude: rectangle.lng,
        latitude: rectangle.lat,
      }));

    return [...formatedCircles, ...formatedPolygons, ...formatedRectangles];
  };

  if (
    sidebarCampaignInfo.timezone &&
    (sidebarCampaignInfo.country || ignoreCountry) &&
    state.auth.userData
  ) {
    let request: CampaignCreateUpdateParams = {
      ...extractPartialRequestForLogs(state),
      isAgreementChecked: true,
      ioId: app.savedIoId,
      countryId: String(sidebarCampaignInfo.country?.value as number),
      creativeIds: state.creatives.selectedCreatives
        .filter((i) => !i.isHeader)
        .map((i) => i.id)
        .join(','),
      isAdvanceAudioVideoTargeted: state.creatives.isAdvanceAudioVideoTargeted || false,
      creativeAdvanceTargeting: state.creatives.creativeAdvanceTargeting,
      creativesPlacementMapping:
        state.creatives.selectedCreativeType?.value === VIDEO_CREATIVE_ID ||
        state.creatives.selectedCreativeType?.value === AUDIO_CREATIVE_ID
          ? state.creatives.creativesPlacementMapping
          : undefined,
      advertiserDomain:
        /^(http|https):\/\//.test(state.creatives.advertiserUrl) ||
        !state.creatives.advertiserUrl.trim().length
          ? state.creatives.advertiserUrl
          : `https://${state.creatives.advertiserUrl}`,
      totalBudgetPacing: state.advanced.budgetPacing,
      deviceType: technology.sidebarCampaignInfo[CampaignInfoField.deviceTypes]
        .map((i: Option) => i.value)
        .join(','),
      manufacturer: technology.sidebarCampaignInfo[CampaignInfoField.manufacturers]
        .map((i: Option) => i.value)
        .join(','),
      os: technology.sidebarCampaignInfo[CampaignInfoField.os].map((i) => i.value).join(','),
      carriers: technology.sidebarCampaignInfo[CampaignInfoField.carriers]
        .map((i: Option) => i.value)
        .join(','),
      networkType: technology.sidebarCampaignInfo[CampaignInfoField.network]
        .map((i: Option) => i.value)
        .join(','),
      trafficType: technology.sidebarCampaignInfo[CampaignInfoField.traffic]
        .map((i: Option) => i.value)
        .join(','),
      isTvAd: technology.isTvAd,
      bidOptimization: bid.bidOptimization,
      bidPacing: bid.bidPacing,
      publisherAdCategory: publishers.selectedPublishers.map((i) => i.value).join(','),
      // TODO: To be removed in future
      // campaignGroupIds: sidebarCampaignInfo.campaignGroups
      //   .map((i) => i.value)
      //   .sort((a, b) => a - b)
      //   .join(','),
      impressionCapping: bid.impressionsCapping ? +bid.impressionsCappingValue : 0,
      ageRangeIds: demographic.sidebarCampaignInfo[CampaignInfoField.age]
        .map((i) => i.value)
        .join(','),
      genderIds: demographic.sidebarCampaignInfo[CampaignInfoField.gender]
        .map((i) => i.value)
        .join(','),
      languageIds: demographic.sidebarCampaignInfo[CampaignInfoField.language]
        .map((i) => i.value)
        .join(','),
      interestIds: demographic.sidebarCampaignInfo[CampaignInfoField.interest]
        .map((i) => i.value)
        .join(','),
      incomeRangeIds: demographic.sidebarCampaignInfo[CampaignInfoField.incomeRange]
        .map((i) => i.value)
        .join(','),
      ethnicityIds: demographic.sidebarCampaignInfo[CampaignInfoField.nationality]
        .map((i) => i.value)
        .join(','),
      blackListedInventoryGroupIds: inventory.sidebarCampaignInfo[CampaignInfoField.inventoryGroups]
        .filter((i: ExcludedOption) => i.excluded)
        .map((i) => i.value)
        .join(','),
      whiteListedInventoryGroupIds: inventory.sidebarCampaignInfo[CampaignInfoField.inventoryGroups]
        .filter((i: ExcludedOption) => i.included)
        .map((i) => i.value)
        .join(','),
      groupDealId: inventory.sidebarCampaignInfo[CampaignInfoField.dealGroups]
        .map((i) => i.value)
        .join(','),
      pmpDealIds: inventory.sidebarCampaignInfo[CampaignInfoField.pmpDeals].map((i) => i.value),
      scheduling: scheduling.sidebarCampaignInfo[CampaignInfoField.scheduling],
      whiteListedDeviceId:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedDeviceId],
      whiteListedIp: advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedIp],
      whiteListedPackageName:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedPackageName],
      whiteListedAppId: advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedAppId],
      whiteListedSiteDomain:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedSiteDomain],
      blackListedDeviceId:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedDeviceId],
      blackListedIp: advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedIp],
      blackListedPackageName:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedPackageName],
      blackListedAppId: advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedAppId],
      blackListedSiteDomain:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedSiteDomain],
      exchanges: advancedTargeting.sidebarCampaignInfo[CampaignInfoField.exchanges]
        .map((i) => i.value)
        .join(','),
      ...(app.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED && state.advanced.budgetPacing
        ? {}
        : { maxDayImpressions: bid.impressionsMaxDaily !== '' ? +bid.impressionsMaxDaily : 0 }),
      budgetTypeId: app.budgetTypeId,
      totalImpressions: bid.impressionsMaxTotal !== '' ? +bid.impressionsMaxTotal : 0,
      maxDayClicks: bid.clicksMaxDaily !== '' ? +bid.clicksMaxDaily : 0,
      totalClicks: bid.clicksMaxTotal !== '' ? +bid.clicksMaxTotal : 0,
      maxDayConversions: bid.conversionsMaxDaily !== '' ? +bid.conversionsMaxDaily : 0,
      totalConversions: bid.conversionsMaxTotal !== '' ? +bid.conversionsMaxTotal : 0,
      conversionIds: conversion.sidebarCampaignInfo[CampaignInfoField.conversions].join(','),
      ...(conversion.conversionType ? { conversionTypeId: conversion.conversionType } : {}),
      // location
      whiteListedStateIds: whiteLists.whiteListedStateIds?.join(','),
      blackListedStateIds: blackLists.blackListedStateIds?.join(','),
      whiteListedZipcodes: whiteLists.whiteListedZipcodes?.join(','),
      blackListedZipcodes: blackLists.blackListedZipcodes?.join(','),
      whiteListedLocationIds: whiteLists.whiteListedLocationIds?.join(','),
      blackListedLocationIds: blackLists.blackListedLocationIds?.join(','),
      whiteListedDmaIds: whiteLists.whiteListedDmaIds?.join(','),
      blackListedDmaIds: blackLists.blackListedDmaIds?.join(','),
      whiteListedCongressionalDistrictIds:
        whiteLists.whiteListedCongressionalDistrictIds?.join(','),
      blackListedCongressionalDistrictIds:
        blackLists.blackListedCongressionalDistrictIds?.join(','),
      whiteListedSenateDistrictIds: whiteLists.whiteListedSenateDistrictIds?.join(','),
      blackListedSenateDistrictIds: blackLists.blackListedSenateDistrictIds?.join(','),
      whiteListedHouseDistrictIds: whiteLists.whiteListedHouseDistrictIds?.join(','),
      blackListedHouseDistrictIds: blackLists.blackListedHouseDistrictIds?.join(','),
      whiteListedCityIds: whiteLists.whiteListedCityIds?.join(','),
      blackListedCityIds: blackLists.blackListedCityIds?.join(','),
      whiteListedCountyIds: whiteLists.whiteListedCountyIds?.join(','),
      blackListedCountyIds: blackLists.blackListedCountyIds?.join(','),
      whiteListedGeoRadiusDetails: getListedGeoRadiusDetails(
        true,
        map.preSavedCircles,
        map.preSavedPolygons,
        map.preSavedRectangles,
      ),
      blackListedGeoRadiusDetails: getListedGeoRadiusDetails(
        false,
        map.preSavedCircles,
        map.preSavedPolygons,
        map.preSavedRectangles,
      ),
      // audiences
      whiteListedAudienceIds: getListedAudienceIds(
        audience.sidebarCampaignInfo[CampaignInfoField.audiences],
        true,
      ),
      blackListedAudienceIds: getListedAudienceIds(
        audience.sidebarCampaignInfo[CampaignInfoField.audiences],
        false,
      ),
    } as CampaignCreateUpdateParams;

    if (
      ((estimator?.campaignEstimatorMetaData?.sliderMeta?.sliderReach ||
        estimator?.campaignEstimatorMetaData?.sliderMeta?.sliderImpression) &&
        estimator?.campaignEstimatorMetaData?.sliderMeta?.sliderReach !==
          app.editableCampaign?.campaignEstimatorMetaData?.sliderMeta?.sliderReach) ||
      estimator?.campaignEstimatorMetaData?.sliderMeta?.sliderImpression !==
        app.editableCampaign?.campaignEstimatorMetaData?.sliderMeta?.sliderImpression
    ) {
      request = { ...request, campaignEstimatorMetaData: estimator?.campaignEstimatorMetaData };
    }

    if (bid.conversionsOptimization) {
      request = {
        ...request,
        conversionType: bid.conversionsType ? 'install' : 'non-install',
        appURL: bid.conversionsType ? `https://${bid.appUrl}` : '',
        targetCPI: +bid.targetCpiCpa,
      };
    }

    if (advertiser.isPoliticalAdvertising) {
      request = {
        ...request,
        politicalAdvertiserClientId: advertiser.politicalAdvertiser?.id,
      };
    } else if (
      !advertiser.isPoliticalAdvertising &&
      state.app.editableCampaign?.politicalAdvertiserClientId
    ) {
      request = {
        ...request,
        politicalAdvertiserClientId: 0,
      };
    }

    if (locationFiles.length) {
      request = {
        ...request,
        locationFileIds: locationFiles.map((file) => file.fileId).join(',') || '',
      };

      const updatedLocationIds = locationFiles.reduce((acc: number[], file) => {
        if (file.removedLocationIds) {
          return [...acc, ...file.removedLocationIds];
        }
        return acc;
      }, []);

      if (updatedLocationIds.length) {
        request = {
          ...request,
          removedLocationIds: updatedLocationIds.join(','),
        };
      }
    }

    if (map.radiusUpdatedLocations && Object.keys(map.radiusUpdatedLocations)) {
      request = {
        ...request,
        radiusUpdatedLocations: map.radiusUpdatedLocations,
      };
    }

    const requestEntries = Object.entries(request);
    for (let i = 0; i < requestEntries.length; i += 1) {
      const elementKey = requestEntries[i][0] as keyof CampaignCreateUpdateParams;
      const elementValue = requestEntries[i][1];

      if (elementValue === '' || (typeof elementValue === 'object' && isEmpty(elementValue))) {
        if (
          !state.app.editableCampaign ||
          !state.app.editableCampaign[elementKey as keyof ExistingCampaignData]
        ) {
          delete request[elementKey];
        }
      }
    }

    if (
      state.app.editableCampaign &&
      !isEmpty(state.app.editableCampaign.locationDetails) &&
      !request.locationFileIds
    ) {
      request = {
        ...request,
        locationFileIds: '',
      };
    }

    const { selectedExchanges } = state.advancedTargeting.sidebarCampaignInfo;
    const allExchanges = state.advancedTargeting.exchanges;

    if (
      Array.isArray(selectedExchanges) &&
      Array.isArray(allExchanges) &&
      selectedExchanges.length === allExchanges.length
    ) {
      request = {
        ...request,
        exchanges: '',
      };
    }

    if (publishers.selectedPublishers.length === allPublishers.length) {
      request.publisherAdCategory = '';
    }

    if (state.audience.preBidSegmentIds) {
      request = {
        ...request,
        prebidAudienceSegmentIdList: state.audience.preBidSegmentIds,
      };
    }

    return request;
  }

  return null;
}

export function getChangedParameters(
  oldParams: ExistingCampaignData,
  newParams: CampaignCreateUpdateParams,
) {
  const keys = Object.keys(newParams);
  const result: { [key: string]: any } = {};
  const stringNumber: string[] = ['conversionIds'];
  const sortBeforeCompare: string[] = ['prebidAudienceSegmentIdList'];
  keys.forEach((key) => {
    if (stringNumber.includes(key)) {
      if (
        !compareNumberStrings(
          oldParams[key as keyof ExistingCampaignData] as string,
          newParams[key as keyof CampaignCreateUpdateParams] as string,
        )
      ) {
        result[key] = newParams[key as keyof CampaignCreateUpdateParams];
      }
    } else if (
      sortBeforeCompare.includes(key) &&
      Array.isArray(oldParams[key as keyof ExistingCampaignData]) &&
      Array.isArray(newParams[key as keyof CampaignCreateUpdateParams])
    ) {
      if (
        !isEqual(
          (oldParams[key as keyof ExistingCampaignData] as any[])?.slice?.().sort(),
          (newParams[key as keyof CampaignCreateUpdateParams] as any[])?.slice?.().sort(),
        )
      ) {
        result[key] = newParams[key as keyof CampaignCreateUpdateParams];
      }
    } else {
      // eslint-disable-next-line
      if (
        !isEqual(
          oldParams[key as keyof ExistingCampaignData],
          newParams[key as keyof CampaignCreateUpdateParams],
        )
      ) {
        result[key] = newParams[key as keyof CampaignCreateUpdateParams];
      }
    }
  });

  if (!isEqual(newParams.creativeAdvanceTargeting, oldParams.creativeAdvanceTargeting)) {
    result.isAdvanceAudioVideoTargeted = newParams.isAdvanceAudioVideoTargeted;
  }

  if (result.timezone) {
    if (!result.endTime) {
      result.endTime = newParams.endTime;
    }

    if (oldParams.status === 'draft' || oldParams.status === 'pending') {
      result.startTime = newParams.startTime;
    }
  }

  if (!compareNumberStrings(oldParams.conversionIds as string, newParams.conversionIds as string)) {
    result.conversionTypeId = newParams.conversionTypeId;
  }

  return result;
}

export function extractPartialEstimatorRequest(state: AppState): Partial<CampaignEstimatorParams> {
  const { sidebarCampaignInfo } = state.advanced;
  let request: Partial<CampaignEstimatorParams> = {
    campaignName: sidebarCampaignInfo.campaignName,
    maxBid: +sidebarCampaignInfo.maxBid,
    totalBudget: +sidebarCampaignInfo.totalBudget,
    startTime: Math.round(sidebarCampaignInfo.startDate / 1000),
    endTime: sidebarCampaignInfo.endDate
      ? Math.round(sidebarCampaignInfo.endDate / 1000)
      : sidebarCampaignInfo.endDate || 0,
    timezoneId: sidebarCampaignInfo?.timezone?.id || undefined,
  };

  if (!state.advanced.budgetPacing) {
    request = {
      ...request,
      dailyBudget: +sidebarCampaignInfo.dailyBudget,
    };
  }

  if (state.creatives.selectedCreativeType) {
    request = {
      ...request,
      creativeTypes: state.creatives.creativeRtbTypes.filter(
        (val) =>
          val.value ===
          state.creatives.creativeTypes.filter(
            (val) => val.value === state.creatives.selectedCreativeType?.value,
          )[0].rtbCampaignTypeId,
      )[0].label,
    };
  }

  return request;
}

export function collectRequestEstimator(state: AppState): CampaignEstimatorParams | null {
  const {
    technology,
    bid,
    publishers,
    demographic,
    inventory,
    scheduling,
    advancedTargeting,
    audience,
    conversion,
    advanced,
    map,
  } = state;

  const { sidebarCampaignInfo, whiteLists, blackLists } = advanced;

  const allPublishers = state.publishers.publishers.reduce(
    (acc: Option<number>[], group: NestedOption): Option<number>[] => {
      if (group.options) {
        acc.push(...group.options);
      }
      acc.push({
        label: group.label,
        value: group.value,
      });
      return acc;
    },
    [],
  );

  const getListedGeoRadiusDetails = (
    isWhite: boolean,
    circles: CircleExtended[],
    polygons: PolygonExtended[],
    rectangles: RectangleExtended[],
  ): GeoRadiusData[] => {
    const formatedCircles = circles
      .filter((circle) => !circle.filename && circle.isInclude === isWhite)
      .map((circle) => ({
        unit: 'mile',
        address: circle.address,
        latitude: circle.lat,
        radius: metersToMiles(circle.getRadius()),
        type: circle.type,
        longitude: circle.lng,
        sid: circle.state,
        cid: circle.country,
      }));

    const formatedPolygons = polygons
      .filter((polygon) => !polygon.fileId && !polygon.filename && polygon.isInclude === isWhite)
      .map((polygon) => ({
        polypath: polygon.getPolyPath(),
        unit: 'mile',
        address: polygon.address,
        latitude: polygon.lat,
        type: polygon.type,
        radius: polygon.getRadius(RadiusUnity.mile),
        longitude: polygon.lng,
        sid: polygon.state,
        cid: polygon.country,
      }));

    const formatedRectangles = rectangles
      .filter((rectangle) => rectangle.isInclude === isWhite)
      .map((rectangle) => ({
        polypath: rectangle.getPolyPath(),
        unit: 'mile',
        address: rectangle.address,
        type: rectangle.type,
        radius: rectangle.getRadius(RadiusUnity.mile),
        sid: rectangle.state,
        cid: rectangle.country,
        longitude: rectangle.lng,
        latitude: rectangle.lat,
      }));

    return [...formatedCircles, ...formatedPolygons, ...formatedRectangles];
  };

  if (sidebarCampaignInfo.timezone && sidebarCampaignInfo.country && state.auth.userData) {
    let request: CampaignEstimatorParams = {
      ...extractPartialEstimatorRequest(state),
      isAgreementChecked: true,
      countryIds: String(sidebarCampaignInfo.country.value),
      creativeIds: state.creatives.selectedCreatives
        .filter((i) => !i.isHeader)
        .map((i) => i.id)
        .join(','),
      isAdvanceAudioVideoTargeted: state.creatives.isAdvanceAudioVideoTargeted || false,
      creativeAdvanceTargeting: state.creatives.creativeAdvanceTargeting,
      creativesPlacementMapping:
        state.creatives.selectedCreativeType?.value === VIDEO_CREATIVE_ID ||
        state.creatives.selectedCreativeType?.value === AUDIO_CREATIVE_ID
          ? state.creatives.creativesPlacementMapping
          : undefined,
      advertiserDomain:
        /^(http|https):\/\//.test(state.creatives.advertiserUrl) ||
        !state.creatives.advertiserUrl.trim().length
          ? state.creatives.advertiserUrl
          : `https://${state.creatives.advertiserUrl}`,
      totalBudgetPacing: state.advanced.budgetPacing,
      deviceTypeIds: technology.sidebarCampaignInfo[CampaignInfoField.deviceTypes]
        .map((i: Option) => i.value)
        .join(','),
      deviceTypeNames: technology.sidebarCampaignInfo[CampaignInfoField.deviceTypes]
        .map((i: Option) => i.label)
        .join(','),
      manufacturer: technology.sidebarCampaignInfo[CampaignInfoField.manufacturers]
        .map((i: Option) => i.value)
        .join(','),
      os: technology.sidebarCampaignInfo[CampaignInfoField.os].map((i) => i.value).join(','),
      carriers: technology.sidebarCampaignInfo[CampaignInfoField.carriers]
        .map((i: Option) => i.value)
        .join(','),
      networkType: technology.sidebarCampaignInfo[CampaignInfoField.network]
        .map((i: Option) => i.value)
        .join(','),
      trafficTypeNames: technology.sidebarCampaignInfo[CampaignInfoField.traffic]
        .map((i: Option) => i.label)
        .join(','),
      isTvAd: technology.isTvAd,
      bidOptimization: bid.bidOptimization,
      bidPacing: bid.bidPacing,
      campaignPublisherCategoryTargetingCodes: publishers.selectedPublishers
        .map((i) => i.iab_id)
        .join(','),
      // TODO: To be removed in future
      // campaignGroupIds: sidebarCampaignInfo.campaignGroups
      //   .map((i) => i.value)
      //   .sort((a, b) => a - b)
      //   .join(','),
      impressionCapping: bid.impressionsCapping ? +bid.impressionsCappingValue : 0,
      ageIds: demographic.sidebarCampaignInfo[CampaignInfoField.age].map((i) => i.value).join(','),
      genderIds: demographic.sidebarCampaignInfo[CampaignInfoField.gender]
        .map((i) => i.value)
        .join(','),
      languageIds: demographic.sidebarCampaignInfo[CampaignInfoField.language]
        .map((i) => i.value)
        .join(','),
      interestIds: demographic.sidebarCampaignInfo[CampaignInfoField.interest]
        .map((i) => i.value)
        .join(','),
      incomeIds: demographic.sidebarCampaignInfo[CampaignInfoField.incomeRange]
        .map((i) => i.value)
        .join(','),
      ethnicityIds: demographic.sidebarCampaignInfo[CampaignInfoField.nationality]
        .map((i) => i.value)
        .join(','),
      blacklistInventoryGroupIds: inventory.sidebarCampaignInfo[CampaignInfoField.inventoryGroups]
        .filter((i: ExcludedOption) => i.excluded)
        .map((i) => i.value)
        .join(','),
      whitelistInventoryGroupIds: inventory.sidebarCampaignInfo[CampaignInfoField.inventoryGroups]
        .filter((i: ExcludedOption) => i.included)
        .map((i) => i.value)
        .join(','),
      scheduling: scheduling.sidebarCampaignInfo[CampaignInfoField.scheduling],
      whitelistDeviceIds:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedDeviceId],
      whiteListedIp: advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedIp],
      whitelistAndroidPackages:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedPackageName],
      whitelistAppIds: advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedAppId],
      whitelistSiteDomains:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedSiteDomain],
      blacklistDeviceIds:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedDeviceId],
      blackListedIp: advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedIp],
      blacklistAndroidPackages:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedPackageName],
      blacklistAppIds: advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedAppId],
      blacklistSiteDomains:
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedSiteDomain],
      exchangeURLs: advancedTargeting.detailedExchanges
        .filter((val) => {
          const selectedExchangesIds = advancedTargeting.sidebarCampaignInfo[
            CampaignInfoField.exchanges
          ].map((i) => i.value);
          // @ts-ignore
          if (selectedExchangesIds.includes(val.id)) {
            return true;
          }
          return false;
        })
        .map((val) => val.urlName)
        .join(','),
      maxDayImpressions: bid.impressionsMaxDaily !== '' ? +bid.impressionsMaxDaily : 0,
      totalImpressions: bid.impressionsMaxTotal !== '' ? +bid.impressionsMaxTotal : 0,
      maxDayClicks: bid.clicksMaxDaily !== '' ? +bid.clicksMaxDaily : 0,
      totalClicks: bid.clicksMaxTotal !== '' ? +bid.clicksMaxTotal : 0,
      maxDayConversions: bid.conversionsMaxDaily !== '' ? +bid.conversionsMaxDaily : 0,
      totalConversions: bid.conversionsMaxTotal !== '' ? +bid.conversionsMaxTotal : 0,
      conversionIds: conversion.sidebarCampaignInfo[CampaignInfoField.conversions].join(','),
      // location
      zipCodesIncluded: whiteLists.whiteListedZipcodes?.join(','),
      zipCodesExcluded: blackLists.blackListedZipcodes?.join(','),
      dmaCodesIncluded: sidebarCampaignInfo.selectedDma
        .filter((item) => whiteLists.whiteListedDmaIds?.includes(item.value))
        .map((item) => item.abbreviation)
        .join(),
      dmaCodesExcluded: sidebarCampaignInfo.selectedDma
        .filter((item) => blackLists.blackListedDmaIds?.includes(item.value))
        .map((item) => item.abbreviation)
        .join(),
      congressionalDistrictIds: whiteLists.whiteListedCongressionalDistrictIds?.join(','),
      congressionalDistrictCodes: sidebarCampaignInfo.selectedCongressionalDistricts
        .filter((item) => whiteLists.whiteListedCongressionalDistrictIds?.includes(item.value))
        .map((item) => item.abbreviation)
        .join(),
      congressionalDistrictIdsExcluded: blackLists.blackListedCongressionalDistrictIds?.join(','),
      senateDistrictIdsIncluded: whiteLists.whiteListedSenateDistrictIds?.join(','),
      senateDistrictCodes: sidebarCampaignInfo.selectedSenates
        .filter((item) => whiteLists.whiteListedSenateDistrictIds?.includes(item.value))
        .map((item) => item.abbreviation)
        .join(),
      senateDistrictIdsExcluded: blackLists.blackListedSenateDistrictIds?.join(','),
      houseDistrictIds: whiteLists.whiteListedHouseDistrictIds?.join(','),
      houseDistrictCodes: sidebarCampaignInfo.selectedHouses
        .filter((item) => whiteLists.whiteListedHouseDistrictIds?.includes(item.value))
        .map((item) => item.abbreviation)
        .join(),
      houseDistrictIdsExcluded: blackLists.blackListedHouseDistrictIds?.join(','),
      cityIdsIncluded: whiteLists.whiteListedCityIds?.join(','),
      cityIdsExcluded: blackLists.blackListedCityIds?.join(','),
      countyIdsIncluded: whiteLists.whiteListedCountyIds?.join(','),
      countyIdsExcluded: blackLists.blackListedCountyIds?.join(','),
      whitelistLocationJson: [
        ...state.location.locationFiles.map((val) => val.validLocations).flat(1),
        ...getListedGeoRadiusDetails(
          true,
          map.preSavedCircles,
          map.preSavedPolygons,
          map.preSavedRectangles,
        ),
      ],
      // audiences
      includedAudiencesV2: getListedAudienceEstimatorInfo(
        audience.sidebarCampaignInfo[CampaignInfoField.audiences],
        true,
      ),
      excludedAudiencesV2: getListedAudienceEstimatorInfo(
        audience.sidebarCampaignInfo[CampaignInfoField.audiences],
        false,
      ),
    } as CampaignEstimatorParams;

    if (sidebarCampaignInfo[CampaignInfoField.states]) {
      const selectedState = sidebarCampaignInfo[CampaignInfoField.states];
      request = {
        ...request,
        stateIds: selectedState.map((i) => i.value).join(','),
        stateCodes: selectedState.map((i) => i.abbreviation).join(','),
      };
    }

    const requestEntries = Object.entries(request);
    for (let i = 0; i < requestEntries.length; i += 1) {
      const elementKey = requestEntries[i][0] as keyof CampaignEstimatorParams;
      const elementValue = requestEntries[i][1];

      if (elementValue === '' || (typeof elementValue === 'object' && isEmpty(elementValue))) {
        if (
          !state.app.editableCampaign ||
          !state.app.editableCampaign[elementKey as keyof ExistingCampaignData]
        ) {
          delete request[elementKey];
        }
      }
    }

    if (publishers.selectedPublishers.length === allPublishers.length) {
      request.campaignPublisherCategoryTargetingCodes = '';
    }

    if (
      state.app.editableCampaign &&
      !isEmpty(state.app.editableCampaign.locationDetails) &&
      !request.locationFileIds
    ) {
      request = {
        ...request,
        locationFileIds: '',
      };
    }

    return request;
  }

  return null;
}
