/* global google */
import React from 'react';
import { Data as DataComponent } from '@react-google-maps/api';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { API } from 'api';
import { MapContext } from 'components/Map';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { AppState } from 'models/Store';
import { Data } from 'models/Google';
import {
  LocationOption,
  DistrictOption,
  IStateOption,
  StateRegionOption,
  ICountryOption,
} from 'models/Location';
import { Option } from 'models/Option';
import { CircleExtended } from 'models/CircleExtended';
import {
  AddGeoJsonData,
  EmptySelectedAreasIds,
  mapActions,
  PushPreSavedCircle,
  PushSelectedAreaId,
  SetActiveFeaturesIds,
  SetMapBounds,
  SetToastMessage,
} from 'store/map/actions';
import { WhiteLists } from 'store/advance/reducer';
import { SOMETHING_WENT_WRONG } from 'constants/messages';
import { whiteListNameByTagName } from 'constants/location';
import { MapListedTagsNames } from 'pages/MainPage/CampaignPage/LocationBlock/Tags';
import { Open, toastActions } from 'store/toast/actions';
import { milesToMeters } from 'utils/format';

export type TypeIds = 'cd' | 'sd' | 'hd' | 'dma' | 'ci' | 'co' | 'st' | 'zc';
export type TypeNames = MapListedTagsNames;

type SelectedLocation =
  | 'selectedDistricts'
  | 'selectedSenates'
  | 'selectedHouses'
  | 'selectedDma'
  | 'selectedStates'
  | 'selectedZipCodes'
  | 'selectedCities'
  | 'selectedCounties';

type TFeaturesCacheItem = { [id: string]: any };

type TFeaturesCache = { [id in TypeIds]: TFeaturesCacheItem };

export const TYPES_IDS: { [id in TypeNames]: TypeIds } = {
  districts: 'cd',
  senates: 'sd',
  houses: 'hd',
  dma: 'dma',
  cities: 'ci',
  counties: 'co',
  states: 'st',
  zipcodes: 'zc',
};

export const TYPES_NAMES: { [id in TypeIds]: TypeNames } = {
  cd: 'districts',
  sd: 'senates',
  hd: 'houses',
  dma: 'dma',
  ci: 'cities',
  co: 'counties',
  st: 'states',
  zc: 'zipcodes',
};

interface Props
  extends PushPreSavedCircle,
    PushSelectedAreaId,
    SetToastMessage,
    EmptySelectedAreasIds,
    AddGeoJsonData,
    SetActiveFeaturesIds,
    SetMapBounds {
  selectedState: LocationOption | null;
  selectedDistricts: DistrictOption[];
  selectedSenates: DistrictOption[];
  selectedHouses: DistrictOption[];
  selectedDma: DistrictOption[];
  selectedStates: IStateOption[];
  selectedCities: DistrictOption[];
  selectedCounties: DistrictOption[];
  areaType: string | null;
  whiteLists: WhiteLists;
  isUsingV2API: boolean;
  selectedCountry: ICountryOption | null;
  selectedZipCodes: Option<any>[];
  geoJSONData: { [id in TypeIds]: TFeaturesCacheItem };
  activeFeaturesIds: { [id: string]: string[] };
  openToast: Open['open'];
}

interface State {}
class MapDataComponent extends React.PureComponent<Props, State> {
  instanceData: Data | null = null;

  stateIdToAbbreviation: Map<number, string>;

  constructor(props: Props) {
    super(props);
    this.stateIdToAbbreviation = new Map<number, string>();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const {
      selectedDistricts,
      selectedSenates,
      selectedHouses,
      selectedDma,
      selectedStates,
      selectedCities,
      selectedCounties,
      selectedZipCodes,
    } = this.props;

    if (!isEqual(prevProps.selectedStates, selectedStates)) {
      this.fetchGeoJSONData(selectedStates, TYPES_IDS.states, 'selectedStates');
    }

    if (!isEqual(prevProps.selectedDistricts, selectedDistricts)) {
      this.fetchGeoJSONData(selectedDistricts, TYPES_IDS.districts, 'selectedDistricts');
    }

    if (!isEqual(prevProps.selectedSenates, selectedSenates)) {
      this.fetchGeoJSONData(selectedSenates, TYPES_IDS.senates, 'selectedSenates');
    }

    if (!isEqual(prevProps.selectedHouses, selectedHouses)) {
      this.fetchGeoJSONData(selectedHouses, TYPES_IDS.houses, 'selectedHouses');
    }

    if (!isEqual(prevProps.selectedCities, selectedCities)) {
      this.fetchGeoJSONData(selectedCities, TYPES_IDS.cities, 'selectedCities');
    }

    if (!isEqual(prevProps.selectedCounties, selectedCounties)) {
      this.fetchGeoJSONData(selectedCounties, TYPES_IDS.counties, 'selectedCounties');
    }

    if (!isEqual(prevProps.selectedZipCodes, selectedZipCodes)) {
      this.fetchGeoJSONData(selectedZipCodes, TYPES_IDS.zipcodes, 'selectedZipCodes');
    }

    if (!isEqual(prevProps.selectedDma, selectedDma)) {
      this.fetchGeoJSONData(selectedDma, TYPES_IDS.dma, 'selectedDma');
    }

    this.setLocationStyle();
  }

  setLocationStyle = () => {
    this.instance.setStyle((feature) => {
      const typeName: TypeNames = feature.getProperty('type-name');
      const value = feature.getProperty('value');
      const whiteList = this.props.whiteLists[whiteListNameByTagName[typeName]];
      const isIncluded = whiteList?.includes(value);
      const color = isIncluded ? 'green' : 'red';
      return {
        fillColor: color,
        strokeColor: color,
      };
    });
  };

  get instance() {
    return this.instanceData as Data;
  }

  fetchGeoJSONData = (
    value: (DistrictOption | StateRegionOption)[],
    key: TypeIds,
    propKey: SelectedLocation,
  ) => {
    const { context: mapInstance } = this;
    // to check whether the location's still selected after API response is received, and if selection is not there, then to not add it in map
    let isLocationStillSelected = true;
    // Filtering value for zipcoes, we will plot only the first 1000 zipcoes on the map
    const filterValue = key === TYPES_IDS.zipcodes ? value.slice(0, 1000) : value;
    Promise.all(
      filterValue.map(async (item: DistrictOption | StateRegionOption) => {
        if (this.props.geoJSONData[key][item.label]) {
          if (!this.props.activeFeaturesIds[key].includes(item.label)) {
            const featureItem: google.maps.Data.Feature = this.instance.addGeoJson(
              this.props.geoJSONData[key][item.label],
            )[0];
            featureItem.setProperty('id', `${key}-${item.label}`);
          }
        } else {
          let data;
          if (item.geojsonUrl) {
            data = await API.Location.fetchGeoJSON(item.geojsonUrl);
          }

          if (data?.type) {
            const feature: google.maps.Data.Feature = this.instance.addGeoJson(data)[0];
            feature.setProperty('id', `${key}-${item.label}`);
            feature.setProperty('type-name', TYPES_NAMES[key]);
            feature.setProperty('value', item.value);
            this.props.addGeoJSONData({ key, label: item.label, value: data });
            isLocationStillSelected = (this.props[propKey] as DistrictOption[]).some(
              (location) => location.label === item.label,
            );
            if (isLocationStillSelected) {
              this.props.setActiveFeaturesIds({
                key,
                selectedLabels: [...this.props.activeFeaturesIds[key], item.label],
              });
            }
          }
        }
      }),
    ).then(() => {
      if (isLocationStillSelected) {
        this.props.setActiveFeaturesIds({ key, selectedLabels: value.map((i) => i.label) });
      }
      const bounds = new google.maps.LatLngBounds();
      this.instance.forEach((i) => {
        const id = i.getProperty('id');
        if (
          id.includes(`${key}-`) &&
          !(this.props[propKey] as DistrictOption[])
            .map((location) => location.label)
            .includes(id.replace(`${key}-`, ''))
        ) {
          this.instance.remove(i);
        } else {
          i.getGeometry().forEachLatLng((latLng) => {
            bounds.extend(latLng);
          });
          mapInstance.fitBounds(bounds);
          mapInstance.panToBounds(bounds);
        }
      });
    });
  };

  handleOnLoad = (instance: Data) => {
    this.instanceData = instance;

    // logic to add geojson when user opens/closes map using open map
    Object.keys(this.props.activeFeaturesIds).forEach((locationType) => {
      this.props.activeFeaturesIds[locationType].forEach((locationName) => {
        const feature = this.props.geoJSONData[locationType as keyof TFeaturesCache][locationName];
        if (!this.instance.contains(feature)) {
          this.instance.addGeoJson(feature);
        }
      });
    });

    this.instance.setStyle(() => ({
      fillOpacity: 0.5,
      strokeOpacity: 1,
      strokeWeight: 2,
    }));

    this.setLocationStyle();
  };

  handleMouseOver = (event: any) => {
    const { feature } = event;
    this.instance.overrideStyle(feature, { fillOpacity: 0.5 });
  };

  handleMouseOut = () => {
    this.instance.revertStyle();
  };

  handleClick = async (event: any) => {
    const {
      areaType,
      pushPreSavedCircle,
      pushSelectedAreaId,
      emptySelectedAreasIds,
      setToastMessage,
      setMapBounds,
      openToast,
    } = this.props;
    const { latLng } = event;
    if (areaType === 'Radius') {
      const circle = new google.maps.Circle({
        center: latLng,
        radius: milesToMeters(0.5),
      });
      const circleInstance = new CircleExtended({ circle, isInclude: true });

      try {
        await circleInstance.update();
        emptySelectedAreasIds();
        pushPreSavedCircle(circleInstance);
        pushSelectedAreaId(circleInstance.id);
        setMapBounds(circle.getBounds());
      } catch (error) {
        if (error === 'ZERO_RESULTS') {
          return;
        }
        if (window.self !== window.top) {
          setToastMessage(SOMETHING_WENT_WRONG);
        } else {
          openToast(SOMETHING_WENT_WRONG);
        }
      }
    }
  };

  render() {
    return (
      /* eslint-disable */
      <DataComponent
        onLoad={this.handleOnLoad}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onClick={this.handleClick}
      />
    );
  }
}

const mapState = (state: AppState) => {
  return {
    selectedState: state.map.selectedState,
    selectedStates: state.advanced.sidebarCampaignInfo[CampaignInfoField.states],
    selectedDistricts: state.advanced.sidebarCampaignInfo[CampaignInfoField.districts],
    selectedSenates: state.advanced.sidebarCampaignInfo[CampaignInfoField.senates],
    selectedHouses: state.advanced.sidebarCampaignInfo[CampaignInfoField.houses],
    selectedDma: state.advanced.sidebarCampaignInfo[CampaignInfoField.dma],
    selectedCities: state.advanced.sidebarCampaignInfo[CampaignInfoField.cities],
    selectedCounties: state.advanced.sidebarCampaignInfo[CampaignInfoField.counties],
    selectedCountry: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
    selectedZipCodes: state.advanced.sidebarCampaignInfo[CampaignInfoField.zipcodes],
    isUsingV2API: state.advanced.isUsingV2API,
    areaType: state.map.areaType,
    whiteLists: state.advanced.whiteLists,
    geoJSONData: state.map.geoJSONData,
    activeFeaturesIds: state.map.activeFeaturesIds,
  };
};

const actions = {
  pushPreSavedCircle: mapActions.pushPreSavedCircle,
  pushSelectedAreaId: mapActions.pushSelectedAreaId,
  setToastMessage: mapActions.setToastMessage,
  emptySelectedAreasIds: mapActions.emptySelectedAreasIds,
  setMapBounds: mapActions.setMapBounds,
  addGeoJSONData: mapActions.addGeoJSONData,
  setActiveFeaturesIds: mapActions.setSelectedLocationIds,
  openToast: toastActions.open,
};

MapDataComponent.contextType = MapContext;

export const MapData = connect(mapState, actions)(MapDataComponent);
