import { SvgIconProps } from '@applift/factor';
import {
  CreativeAudio,
  CreativeHTML,
  CreativeImage,
  CreativeVideo,
  CreativeNative,
} from '@applift/icons';

export type TCreativeTypeIds = 11 | 13 | 14 | 16 | 17 | 18 | 15;

export interface CreativeListOrganizationDetails {
  owId: number;
  organizationName: string;
  organizationLogo: string;
}

export interface CreativeListUserDetails {
  uowId: number;
  userName: string;
  userEmail: string;
  hasEditAccess: boolean;
  hasApprovalAccess: boolean;
}

export interface CreativeListCampaignDetails {
  campaignIds?: number[];
}

export type NewCreativeObjectType = {
  creativeId: number;
  creativeName: string;
  platformCreativeTypeId: number;
  creativeStatusId?: number;
  creativePreviewFlag: number;
  creativeSource: string;
  creativeThumbnailSource: string;
  creativeCardSource: string;
  createdAt: number;
  externalCreativeId?: string;
  modifiedAt: number;
  clickUrl: string | null;
  creativeHeight: number | null;
  creativeWidth: number | null;
  duration: number | null;
  subMediaType?: string; // NOT SURE
  pixelUrl?: string;
  campaignDetails?: CreativeListCampaignDetails;
  userDetails?: CreativeListUserDetails;
  organizationDetails?: CreativeListOrganizationDetails;
  approvalRequiredPostUrlUpdate?: boolean;

  // ! NOT VERIFIED VALUES
  creativeScreenshotSource: string;
  isTransformed?: number;
};

export interface Creative {
  clickUrl: string | null;
  createdOn: number;
  created: string;
  creativeSource: string;
  creativeThumbnailSource: string;
  creativeType: string;
  creativeTypeId: TCreativeTypeIds;
  height: number;
  id: number;
  name: string;
  status: string;
  uuid: string;
  width: number;
  creativeCardSource: string | null;
  duration?: number;
  isTransformed?: number;
  campaignCount: number;
  pixelUrls?: string[];
  isHeader?: boolean;
  creativeScreenshotSource?: string;
  label?: string;
  value?: number;
  previewFlag: 0 | 1 | -1;
  nativeMediaType?: string;
  externalCreativeId?: string;
  campaignDetails?: CreativeListCampaignDetails;
  userDetails?: CreativeListUserDetails;
  organizationDetails?: CreativeListOrganizationDetails;
  approvalRequiredPostUrlUpdate?: boolean;
}

export const creativesIDMap: { [key: number]: string } = {
  11: 'CreativeImage',
  13: 'CreativeHtml',
  14: 'CreativeVideo',
  17: 'CreativeAudio',
  15: 'CreativeNative',
};

export const creativesIDMapV2: { [key: number]: string } = {
  11: 'Image',
  13: 'HTML',
  14: 'Video',
  15: 'Native',
  17: 'Audio',
};

export const creativesStatusMapper: { [key: string]: string } = {
  running: '2',
  pending: '1',
  rejected: '5',
  deleted: '4',
  paused: '3',
};

export const creativeTypeWithIconMap: {
  [key: string]: React.MemoExoticComponent<
    React.ForwardRefExoticComponent<
      Omit<SvgIconProps, 'children'> & React.RefAttributes<HTMLOrSVGElement>
    >
  >;
} = {
  Image: CreativeImage,
  HTML: CreativeHTML,
  Video: CreativeVideo,
  Audio: CreativeAudio,
  Native: CreativeNative,
};

export const IMAGE_CREATIVE_ID = 11;
export const HTML_CREATIVE_ID = 13;
export const VIDEO_CREATIVE_ID = 14;
export const NATIVE_CREATIVE_ID = 15;
export const TICKER_CREATIVE_ID = 16;
export const AUDIO_CREATIVE_ID = 17;
export const GOTV_CREATIVE_ID = 18;

export const creativeStatusMappingById = {
  1: {
    name: 'pending',
    order: 1,
    displayName: 'Pending',
  },
  2: {
    name: 'running',
    order: 2,
    displayName: 'Running',
  },
  3: {
    name: 'paused',
    order: 3,
    displayName: 'Paused',
  },
  4: {
    name: 'deleted',
    order: 5,
    displayName: 'Deleted',
  },
  5: {
    name: 'rejected',
    order: 4,
    displayName: 'Rejected',
  },
};

export const creativeTypeMappingById = {
  0: {
    name: 'All',
    order: 0,
    displayName: 'ALL',
    rtbCreativeTypeId: null,
  },
  11: {
    name: 'Image',
    order: 95,
    displayName: 'Image',
    rtbCreativeTypeId: 1,
  },
  13: {
    name: 'HTML',
    order: 96,
    displayName: 'HTML',
    rtbCreativeTypeId: 1,
  },
  14: {
    name: 'Video',
    order: 97,
    displayName: 'Video',
    rtbCreativeTypeId: 3,
  },
  15: {
    name: 'Native',
    order: 99,
    displayName: 'Native',
    rtbCreativeTypeId: 4,
  },
  17: {
    name: 'Audio',
    order: 98,
    displayName: 'Audio',
    rtbCreativeTypeId: 2,
  },
};

export type CreativeListParams = {
  pageNo?: number;
  noOfEntries?: number;
  searchField?: string;
  creativeTypeIds?: string;
  statusIds?: number[];
  creativeIds?: string;
  sortBy?: string;
  sortType?: 'asc' | 'desc';
  creativeGroupId?: number;
  campaignIds?: number[];
  owIds?: number[];
  activeCreatives?: boolean;
  createdAfter?: number;
  allOwIds?: boolean;
  includeOrganizationDetails?: boolean;
  includeUserDetails?: boolean;
};
