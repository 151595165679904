import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Box,
  Row,
  Col,
  Paper,
  Button,
  enqueueSnackbar,
  Tooltip,
  Typography,
} from '@applift/factor';
import { Add } from '@applift/icons';
import { DEFAULT_LIST_SORTING, DEFAULT_PAGE_SIZE } from 'constants/campaignList';
import {
  RowSelectionState,
  SortingState,
  VisibilityState,
  constructListFromColumnDef,
} from '@applift/datagrid';

import { useCampaignList, useCampaignListCols, useCampaignTypes } from 'hooks/useCampaignList';
import { useDebounceValue } from 'hooks/useDebounceValue';
import { useTimezone } from 'hooks/useTimezone';
import { useDownloadCampaignList } from 'hooks/useDownloadCampaignList';
import { AppState } from 'models/Store';
import { CampaignCreativeCountType, CampaignStatusCountType } from 'models/Count';
import { OptionId } from 'iqm-framework';
import { useCampaignStatusCount } from 'hooks/useCampaignStatusCount';
import { useCampaignCreativeCount } from 'hooks/useCampaignCreativeCount';
import { IoBudgetImpressionInfoType, IODetail } from 'models/IO';
import { DateRange } from 'models/Date';
import { CampaignListFileType, CampaignListType } from 'models/CampaignList';
import {
  usePutCampaignPriority,
  useSetCampaignBudget,
  useSetCampaignStatus,
  useUpdateCampaignEndDate,
} from 'hooks/useCampaign';
import { BUDGET_TYPE_ID, IO_STATUSES } from 'constants/apps';
import { IO_STATUS_ID } from 'constants/insertionOrder';
import { CampaignTypeByName } from 'models/campaign';
import { advertiserActions, SetBidShadingApplicability } from 'store/advertiser/actions';
import { DataDogLogger } from 'services/DataDog';
import { User } from 'models/User';
import { useCustomerConfigInfo } from 'hooks/useUser';
import { IBidModelRequest } from 'api/Campaign';
import { getApiCampaignListSorting, sortingString } from 'utils/sorting';

import { CampaignListFilters } from './CampaignListFilters';
import { ActionPanel } from './ActionPanel';
import { CampaignList } from './CampaignList';
import { getCampaignsTableColDef } from './colDef';
import { SetCampaignDateAndTimezone } from '../SetCampaignDateAndTimezoneDialog/SetCampaignDateAndTimezone';
import { DuplicateCampaign } from '../DuplicateCampaignDialog/DuplicateCampaign';
import { SetCampaignTotalBudget } from '../SetCampaignTotalBudgetDialog';
import { DailyBudgetAction } from '../DailyBudgetAction/DailyBudgetAction';
import { SetCampaignMaxBid } from '../SetCampaignMaxBid/SetCampaignMaxBid';
import { DeleteAction } from '../DeleteAction';
import { RunOrRestore } from '../RunOrRestoreDialog';
import { PauseCampaign } from '../PauseCampaign';
import { SetCampaignPriorityDialog } from '../SetCampaignPriorityDialog';
import { DuplicatePGCampaign } from '../DuplicatePGCampaignDialog';
import { CreateCampaignDialog } from './CreateCampaignDialog';

export interface CampaignListWrapperPropTypes extends SetBidShadingApplicability {
  isIoDetailsDirty: boolean;
  ioDetail: IODetail | undefined;
  organizationTimezone: number | OptionId<any> | null;
  openIoSaveDialog: boolean;
  setOpenIoSaveDialog: (arg: boolean) => void;
  ioBudgetInfo: IoBudgetImpressionInfoType | undefined;
  owId?: number;
  ioId?: AppState['advanced']['sidebarCampaignInfo']['ioId'];
  userData: User;
}

const CampaignListWrapperComponent = (props: CampaignListWrapperPropTypes) => {
  const {
    isIoDetailsDirty,
    ioDetail,
    organizationTimezone,
    openIoSaveDialog,
    setOpenIoSaveDialog,
    ioBudgetInfo,
    owId,
    setBidShadingApplicability,
    userData,
  } = props;

  const CustomerConfigResponse = useCustomerConfigInfo(owId as number, (res) =>
    setBidShadingApplicability(res.isBidShadingEnabled),
  );

  const history = useHistory();
  const timezone = useTimezone();
  const timezoneOptions = timezone.data;
  const { id: ioId } = useParams<{ id: string }>();

  const [search, setSearch] = React.useState('');
  const [sorting, setSorting] = React.useState<SortingState>(DEFAULT_LIST_SORTING);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const [statusFilter, setStatusFilter] = React.useState<CampaignStatusCountType['status_key'][]>([
    'all',
  ]);
  const [selectedCampaign, setSelectedCampaign] = React.useState<number[]>([]);
  const [creativeTypeFilter, setCreativeTypeFilter] = React.useState<
    CampaignCreativeCountType['creativeTypeId'][]
  >([]);
  const [timezoneFilter, setTimezoneFilter] = React.useState<OptionId<string> | undefined>(
    undefined,
  );
  const [dateRange, setDateRange] = React.useState<DateRange>();
  const [dialogToShow, setDialogToShow] = React.useState<
    | null
    | 'duplicate'
    | 'endDate'
    | 'budgetTotal'
    | 'budgetDaily'
    | 'maxBid'
    | 'delete'
    | 'run'
    | 'restore'
    | 'pause'
    | 'priority'
  >(null);
  const [isCampaignPriorityMutationLoading, setIsCampaignPriorityMutationLoading] =
    React.useState(false);

  const [selectedCampaignTypeId, setSelectedCampaignTypeId] = React.useState<number[] | undefined>(
    [],
  );

  const [showCampaignCreateDialog, setShowCampaignCreateDialog] = React.useState(false);

  const { isBidShadingEnabled, isVldEnabled, hadVldGenerated } = CustomerConfigResponse.data || {};

  const defaultData = React.useMemo(
    () =>
      constructListFromColumnDef(
        isBidShadingEnabled
          ? getCampaignsTableColDef(ioDetail)
          : getCampaignsTableColDef(ioDetail).map((item) =>
              item.id === 'budget'
                ? {
                    ...item,
                    // @ts-ignore
                    columns: item?.columns?.filter(
                      (item: { id: string }) => item.id !== 'organizationBidShadingSaving',
                    ),
                  }
                : item,
            ),
      ),
    [ioDetail, isBidShadingEnabled],
  );

  const [columnVisibility, setColumnVisibility] = React.useState(defaultData[1]);
  const defaultDeselectedColumns = React.useMemo(
    () =>
      Object.keys(defaultData[1]).reduce((prev, one) => {
        // eslint-disable-next-line
        prev[one] = false;
        return prev;
      }, {} as VisibilityState),
    [defaultData],
  );

  const { data: campaignListCols } = useCampaignListCols();

  React.useEffect(() => {
    if (
      campaignListCols &&
      campaignListCols.config &&
      Object.keys(campaignListCols.config).includes('columnVisibility')
    ) {
      const visib = campaignListCols.config.columnVisibility;
      setColumnVisibility(visib);
    }
    if (
      campaignListCols &&
      campaignListCols.config &&
      !Object.keys(campaignListCols.config).includes('columnVisibility')
    ) {
      setColumnVisibility(defaultData[1]);
    }
  }, [campaignListCols, defaultData]);

  const downloadMutation = useDownloadCampaignList({
    onSuccess: (val) => {
      const downloadUrl = val.data?.url;
      if (downloadUrl) {
        window.open(downloadUrl);
      }
    },
  });

  React.useEffect(() => {
    if (timezoneOptions?.length && !timezoneFilter && organizationTimezone) {
      setTimezoneFilter(
        timezoneOptions
          ?.filter(
            (timezone) =>
              timezone.id ===
              (typeof organizationTimezone === 'number'
                ? organizationTimezone
                : organizationTimezone.id),
          )
          .map((orgTimezone) => ({
            label: orgTimezone.label,
            id: orgTimezone.id,
            value: orgTimezone.name,
          }))[0],
      );
    }
  }, [timezone, organizationTimezone, timezoneFilter, timezoneOptions]);

  const selectedStatus = React.useMemo(() => {
    if (statusFilter[0] === 'all') {
      return '';
    }
    if (statusFilter.length) {
      return statusFilter.join(',');
    }
    return '';
  }, [statusFilter]);

  const { data: campaignCreativeCount, isLoading: campaignCreativeCountLoading } =
    useCampaignCreativeCount(ioId, selectedStatus);

  const debouncedSearch = useDebounceValue(search, 500);
  const selectedIds = Object.keys(rowSelection).map((id) => Number(id));

  const creativeTypeFilterPayload = React.useMemo(
    () => creativeTypeFilter?.filter(Boolean).join(','),
    [creativeTypeFilter],
  );

  const { data: campaignStatusCount, isLoading: campaignStatusCountLoading } =
    useCampaignStatusCount(ioId);

  const statusFilterPayload = React.useMemo(
    () =>
      statusFilter[0] === 'all'
        ? campaignStatusCount?.data
            ?.filter((val) => val.status_key !== 'all')
            .map((val) => val.status_key)
            .join(',')
        : statusFilter.join(','),
    [campaignStatusCount?.data, statusFilter],
  );

  const { data: campaignTypes } = useCampaignTypes(
    {
      ioIds: [ioId as unknown as number],
      campaignIds: selectedCampaign,
      creativeTypeIds: creativeTypeFilter,
      statusList: statusFilterPayload?.split(','),
    },
    {
      enabled: !!ioId,
    },
  );

  const selectedCampaignPayload = React.useMemo(
    () => (selectedCampaign.length ? selectedCampaign.join(',') : undefined),
    [selectedCampaign],
  );
  const prevTotalRecordsRef = React.useRef<number>();

  const {
    data: campaignListData,
    isLoading: campaignListLoading,
    fetchNextPage,
  } = useCampaignList(
    debouncedSearch,
    DEFAULT_PAGE_SIZE,
    sorting,
    [+ioId],
    statusFilterPayload,
    timezoneFilter?.id,
    creativeTypeFilterPayload,
    selectedCampaignPayload,
    dateRange?.start as unknown as number,
    dateRange?.end as unknown as number,
    ioBudgetInfo,
    ioDetail,
    selectedCampaignTypeId,
    {
      enabled: !!statusFilterPayload?.length,
      onSuccess: (data) => {
        prevTotalRecordsRef.current = data.pages[0]?.data?.totalRecords;
      },
    },
  );

  const setStatusFilterWrapper = React.useCallback((val) => {
    setSelectedCampaignTypeId([]);
    setSelectedCampaign([]);
    setCreativeTypeFilter([]);
    setStatusFilter(val);
  }, []);

  const setCreativeTypeFilterWrapper = React.useCallback((val) => {
    setSelectedCampaignTypeId([]);
    setSelectedCampaign([]);
    setCreativeTypeFilter(val);
  }, []);

  const setSelectedCampaignFilterWrapper = React.useCallback((val) => {
    setSelectedCampaignTypeId([]);
    setSelectedCampaign(val);
  }, []);

  const filteredRecords = React.useMemo(
    () => campaignListData?.pages[0].data?.filteredRecords,
    [campaignListData?.pages],
  );

  const totalRecords = React.useMemo(
    () => campaignListData?.pages[0].data?.totalRecords,
    [campaignListData?.pages],
  );

  const footerData = campaignListData?.pages[0].data?.recordsTotal;

  const flatData = React.useMemo(() => {
    return (
      campaignListData?.pages
        ?.map((page) => {
          return page.data?.recordsList ?? [];
        })
        .flat(1) || []
    );
  }, [campaignListData]);

  const selectedItems = flatData.filter((data) => data && selectedIds.includes(data.campaignId));

  const displaySuccessSnackbar = (type: string, count?: number) =>
    enqueueSnackbar(
      <Typography>
        <Typography variant="span" component="span" weight="demi" sx={{ mr: 4 }}>
          {type}
        </Typography>
        updated successfully for
        <Typography variant="span" component="span" weight="demi" sx={{ mx: 4 }}>
          {selectedItems.length === 1
            ? selectedItems[0]?.campaignName
            : `${count ?? selectedItems.length} campaigns`}
          .
        </Typography>
      </Typography>,
      {
        variant: 'success',
      },
    );

  const onBudgetSetSuccess = (response: any) => {
    let type = '';
    if (dialogToShow === 'budgetTotal') {
      type = 'Total Budget';
    } else {
      type = dialogToShow === 'budgetDaily' ? 'Daily Budget' : 'Max Bid Price';
    }
    if (response?.modified_data?.length) {
      displaySuccessSnackbar(type, response.modified_data.length);
    }
    if (!response.status) {
      const error = response?.reason?.length ? (
        <>
          {response.reason.map((item: any) => (
            <Typography>
              <Typography variant="span" component="span" weight="demi" sx={{ mr: 4 }}>
                {item.campaignName}
              </Typography>
              - {item.errorMessage}
            </Typography>
          ))}
        </>
      ) : (
        `Failed to update ${type}.`
      );
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
    setDialogToShow(null);
  };

  const onCampaignStatusUpdation = (response: any) => {
    if (response?.updatedData?.length) {
      const isOneCampaign = response?.updatedData.length === 1;
      enqueueSnackbar(
        <Typography>
          <Typography variant="span" component="span" weight="demi" sx={{ mr: 4 }}>
            {isOneCampaign
              ? selectedItems.find(
                  (item) => item?.campaignId === response.updatedData[0]?.campaignId,
                )?.campaignName
              : response.updatedData.length}
          </Typography>
          {isOneCampaign ? 'campaign' : 'campaigns'}
          <Typography variant="span" component="span" weight="demi" sx={{ mx: 4 }}>
            {dialogToShow === 'run' ? 'status updated' : `${dialogToShow}d`}
          </Typography>
          successfully.
        </Typography>,
        {
          variant: 'success',
        },
      );
    }
    if (!response.status) {
      const error = response?.failedData?.length ? (
        <>
          {response.failedData.map((item: any) => (
            <Box>{item.message}</Box>
          ))}
        </>
      ) : (
        `Failed to update campaign(s) status.`
      );
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
    setDialogToShow(null);
  };

  const onEndDateSetCompletion = (res: any) => {
    if (res?.modified_data?.length) {
      displaySuccessSnackbar('End Date', res?.modified_data?.length);
    }
    if (res?.reason?.length) {
      enqueueSnackbar(
        <>
          {res.reason.map((reas: any) => (
            <Typography key={reas}>
              {reas.id ? (
                <Typography variant="span" component="span" weight="demi" sx={{ mr: 4 }}>
                  {selectedItems.find((item) => item?.campaignId === reas.id)?.campaignName}
                </Typography>
              ) : null}
              - {reas.errorMessage}
            </Typography>
          ))}
        </>,
        {
          variant: 'error',
        },
      );
    }
    setDialogToShow(null);
  };

  const colList: { label: string; value: string }[] = React.useMemo(() => {
    const list: { label: string; value: string } | [] = [];
    getCampaignsTableColDef(ioDetail).forEach((data) => {
      // @ts-ignore
      if (data?.columns?.length) {
        // @ts-ignore
        data?.columns?.forEach((row: any) => {
          const title = typeof row.header === 'string' ? row.header : row.meta.headerTitle;
          // @ts-ignore
          list.push({ label: title, value: row.accessorKey });
        });
      } else {
        // @ts-ignore
        list.push({ label: data.header, value: data.accessorKey });
      }
    });
    return list;
  }, [ioDetail]);

  const columns = React.useMemo(() => {
    const mapperToggleForCampaignType: { [key: string]: string } = {
      budgetDay: 'dailyImpression',
      budgetTotal: 'targetImpression',
    };
    return colList
      .filter((col) => {
        if (typeof columnVisibility[col?.value] === 'boolean' && columnVisibility[col?.value]) {
          return true;
        }
        if (Object.prototype.hasOwnProperty.call(defaultDeselectedColumns, col?.value)) {
          return false;
        }
        return true;
      })
      .map((val) => {
        const isImpressionBasedCampaign =
          ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED;
        if (isImpressionBasedCampaign && mapperToggleForCampaignType[val.value]) {
          return {
            label: val.label,
            value: mapperToggleForCampaignType[val.value],
          };
        }
        return { label: val.label, value: val.value };
      });
  }, [colList, columnVisibility, defaultDeselectedColumns, ioDetail?.ioBudgetTypeId]);

  const downloadCampaignList = React.useCallback(
    (fileType: CampaignListFileType) => {
      const campaignListSorting = getApiCampaignListSorting(sorting, ioDetail?.ioBudgetTypeId);

      downloadMutation.mutate({
        fileName: 'Campaign List',
        columns,
        campaignIds: selectedCampaign.join(','),
        campaignStatus: statusFilter.join(','),
        creativeTypeIds: creativeTypeFilter.join(','),
        startDate: new Date(dateRange?.start as Date)?.getTime().toString(),
        fileType,
        endDate: new Date(dateRange?.end as Date)?.getTime().toString(),
        searchField: search,
        timezoneId: timezoneFilter?.id,
        ioIdsList: new Array(ioDetail?.ioId.toString() as string),
        sortBy: sortingString(campaignListSorting).sortBy,
      });
    },
    [
      columns,
      creativeTypeFilter,
      dateRange?.end,
      dateRange?.start,
      downloadMutation,
      ioDetail?.ioId,
      ioDetail?.ioBudgetTypeId,
      search,
      selectedCampaign,
      sorting,
      statusFilter,
      timezoneFilter?.id,
    ],
  );

  const updateBudgetMutation = useSetCampaignBudget(onBudgetSetSuccess, ioId);
  const updateStatusMutation = useSetCampaignStatus(onCampaignStatusUpdation);
  const updateEndDateMutation = useUpdateCampaignEndDate(onEndDateSetCompletion);
  const putCampaignPriorityMutation = usePutCampaignPriority({ editType: 'bulk' });

  const getPriorityMutationToast = (variant: 'success' | 'error', count: number) => {
    return (
      <Typography>
        <Typography weight="demi">Priority</Typography>
        {variant === 'success' ? ' updated successfully for ' : ' could not be updated for '}
        <Typography weight="demi">{count} Campaigns</Typography>
      </Typography>
    );
  };

  const handleBulkSetCampaignPriority = (priority: number) => {
    if (!ioDetail) {
      return;
    }

    setIsCampaignPriorityMutationLoading(true);

    const selectedCampaigns = flatData.filter(
      (cmp) => cmp?.campaignId && rowSelection[cmp.campaignId],
    );
    const campaignsWithoutExistingPriority = selectedCampaigns
      .filter((cmp) => cmp && !cmp.campaignPriority)
      .map((cmp) => cmp!.campaignId);
    const campaignsWithExistingPriority = selectedCampaigns
      .filter((cmp) => cmp && cmp.campaignPriority)
      .map((cmp) => cmp!.campaignId);

    const bidIdOfcampaignsWithExistingPriority = selectedCampaigns
      .filter((cmp) => cmp && cmp.campaignPriority)
      .map((cmp) => cmp!.bidModelDataId);

    const count = campaignsWithoutExistingPriority.length + campaignsWithExistingPriority.length;

    putCampaignPriorityMutation
      .mutateAsync({
        ioId: ioDetail.ioId,
        bidModelRequests: [
          ...campaignsWithoutExistingPriority.map((id) => ({
            action: 'ADD',
            bidModelData: { priority },
            dimensionEntityMappings: [{ campaignId: id }],
          })),
          ...bidIdOfcampaignsWithExistingPriority.map(
            (id) => ({ action: 'UPDATE', bidModelData: { priority, id } } as IBidModelRequest),
          ),
        ] as IBidModelRequest[],
      })
      .then((response) => {
        DataDogLogger.IODetailsPage.setCampaignPriority({
          count: selectedCampaigns.length,
          type: 'bulk',
          response,
        });
        enqueueSnackbar(getPriorityMutationToast('success', count), { variant: 'success' });
      })
      .catch((error) => {
        DataDogLogger.IODetailsPage.setCampaignPriority({
          count: selectedCampaigns.length,
          type: 'bulk',
          error,
        });
        enqueueSnackbar(getPriorityMutationToast('error', count), { variant: 'error' });
      })
      .finally(() => {
        setDialogToShow(null);
        setIsCampaignPriorityMutationLoading(false);
      });
  };

  React.useEffect(() => {
    if (ioDetail?.ioStartTime && ioDetail?.ioEndTime) {
      setDateRange({
        start: new Date(),
        end: new Date(),
      });
    }
  }, [ioDetail?.ioEndTime, ioDetail?.ioStartTime]);

  const renderDialog = () => {
    switch (dialogToShow) {
      case 'budgetDaily':
        return (
          <DailyBudgetAction
            closeDialog={() => setDialogToShow(null)}
            onCompletion={(data) => updateBudgetMutation.mutate(data)}
            selectedCampaigns={selectedItems as CampaignListType[]}
            isLoading={updateBudgetMutation.isLoading}
          />
        );
      case 'budgetTotal':
        return (
          <SetCampaignTotalBudget
            closeDialog={() => setDialogToShow(null)}
            onCompletion={(data) => updateBudgetMutation.mutate(data)}
            selectedCampaigns={selectedItems as CampaignListType[]}
            isLoading={updateBudgetMutation.isLoading}
          />
        );
      case 'duplicate':
        const isEveryPgCampaign = selectedItems.every((val) => val?.campaignTypeId === 2);
        if (isEveryPgCampaign) {
          return (
            <DuplicatePGCampaign
              closeDialog={() => setDialogToShow(null)}
              parentIO={ioDetail as IODetail}
              organizationTimezoneId={
                typeof organizationTimezone === 'number'
                  ? organizationTimezone
                  : organizationTimezone?.id
              }
              campaignDetails={selectedItems as CampaignListType[]}
            />
          );
        }
        return (
          <DuplicateCampaign
            closeDialog={() => setDialogToShow(null)}
            parentIO={ioDetail as IODetail}
            organizationTimezoneId={
              typeof organizationTimezone === 'number'
                ? organizationTimezone
                : organizationTimezone?.id
            }
            campaignDetails={selectedItems as CampaignListType[]}
          />
        );
      case 'endDate':
        return (
          <SetCampaignDateAndTimezone
            closeDialog={() => setDialogToShow(null)}
            selectedCampaigns={selectedItems as CampaignListType[]}
            isLoading={updateEndDateMutation.isLoading}
            organizationTimezoneId={
              typeof organizationTimezone === 'number'
                ? organizationTimezone
                : organizationTimezone?.id
            }
            onCompletion={(data) => updateEndDateMutation.mutate(data)}
            ioDetail={ioDetail as IODetail}
          />
        );
      case 'maxBid':
        return (
          <SetCampaignMaxBid
            closeDialog={() => setDialogToShow(null)}
            onCompletion={(data) => updateBudgetMutation.mutate(data)}
            selectedCampaigns={selectedItems as CampaignListType[]}
            isLoading={updateBudgetMutation.isLoading}
          />
        );
      case 'delete':
        return (
          <DeleteAction
            closeDialog={() => setDialogToShow(null)}
            onCompletion={(data) => updateStatusMutation.mutate(data)}
            selectedCampaigns={selectedItems as CampaignListType[]}
            isLoading={updateStatusMutation.isLoading}
          />
        );
      case 'run':
      case 'restore':
        return (
          <RunOrRestore
            closeDialog={() => setDialogToShow(null)}
            onCompletion={(data) => updateStatusMutation.mutate(data)}
            selectedCampaigns={selectedItems as CampaignListType[]}
            type={dialogToShow === 'restore' ? 'Restore' : 'Run'}
            isLoading={updateStatusMutation.isLoading}
            organizationTimezoneId={
              typeof organizationTimezone === 'number'
                ? organizationTimezone
                : organizationTimezone?.id
            }
          />
        );
      case 'pause':
        return (
          <PauseCampaign
            closeDialog={() => setDialogToShow(null)}
            onCompletion={(data) => updateStatusMutation.mutate(data)}
            selectedCampaigns={selectedItems as CampaignListType[]}
            isLoading={updateStatusMutation.isLoading}
          />
        );
      case 'priority':
        return (
          <SetCampaignPriorityDialog
            closeDialog={() => setDialogToShow(null)}
            onCompletion={handleBulkSetCampaignPriority}
            isLoading={isCampaignPriorityMutationLoading}
            initialPriority={selectedItems.length === 1 ? selectedItems[0]?.campaignPriority : null}
          />
        );
      default:
        return null;
    }
  };

  const getCreateCampaignButtonTooltip = () => {
    if (ioDetail?.ioStatusId === IO_STATUSES.EXPIRED) {
      return 'The newly created campaign won’t serve due to the expiration of the IO end date';
    }
    if (ioDetail?.ioStatusId === IO_STATUSES.DELETED) {
      return `Campaign can't be created for a deleted IO. Please restore the IO to create a campaign.`;
    }
    return '';
  };

  const overlay = React.useMemo(() => {
    if (!flatData.length && !campaignListLoading && !filteredRecords && totalRecords) {
      return 'noResult';
    }
    if (!flatData.length && !campaignListLoading) {
      return 'noRows';
    }
    return undefined;
  }, [flatData.length, filteredRecords, campaignListLoading, totalRecords]);

  return (
    <Box
      sx={{
        mt: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: 100,
      }}
    >
      {/* Below code is commented because layout's height issue */}
      {/* <Row sx={{ mb: 16 }}>
          <Col xs={6}>
            <Box>
              <Typography sx={{ fontSize: 16, textWeight: 'demi', display: 'block' }}>
                Campaigns (0)
              </Typography>
            </Box>
          </Col>
          <Col xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              onClick={() => {
                history.push(`/campaign-create?ioId=${ioId}`);
              }}
              variant="outlined"
              startIcon={<Add />}
              sx={{ ...(!totalRecords ? { visibility: 'hidden' } : { visibility: 'visible' }) }}
            >
              Create Campaign
            </Button>
          </Col>
        </Row> */}
      <Row xs={{ gutterSize: 8 }} sx={{ mb: 8 }}>
        <CampaignListFilters
          ioId={+ioId}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilterWrapper}
          creativeTypeFilter={creativeTypeFilter}
          campaignStatusCount={campaignStatusCount?.data}
          campaignCreativeCount={campaignCreativeCount?.data}
          campaignCreativeCountLoading={campaignCreativeCountLoading}
          campaignStatusCountLoading={campaignStatusCountLoading}
          setCreativeTypeFilter={setCreativeTypeFilterWrapper}
          timezoneFilter={timezoneFilter}
          setTimezoneFilter={setTimezoneFilter}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaignFilterWrapper}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setRowSelection={setRowSelection}
          selectedCampaignTypeId={selectedCampaignTypeId}
          setSelectedCampaignTypeId={setSelectedCampaignTypeId}
          campaignTypes={campaignTypes}
        />
        <Col
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Tooltip placement="top" title={getCreateCampaignButtonTooltip()} arrow>
            <Box sx={{ display: 'inline-flex' }}>
              <Button
                onClick={() => {
                  setShowCampaignCreateDialog(true);
                }}
                variant="contained"
                startIcon={<Add />}
                sx={{
                  minWidth: 100,
                  ...(totalRecords || prevTotalRecordsRef.current
                    ? { visibility: 'visible' }
                    : { visibility: 'hidden' }),
                }}
                disabled={ioDetail?.ioStatusId === IO_STATUS_ID.DELETED}
              >
                Create Campaign
              </Button>
            </Box>
          </Tooltip>
        </Col>
      </Row>
      <Row sx={{ height: 100, alignItems: 'stretch' }}>
        <Col
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', height: 100 }}
          xs={12}
        >
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              height: 100,
            }}
            elevation={2}
          >
            {ioDetail?.ioBudgetTypeId ? (
              <ActionPanel
                search={search}
                setSearch={setSearch}
                selectedItems={(selectedItems as CampaignListType[]) ?? []}
                setRowSelection={setRowSelection}
                totalCount={filteredRecords}
                setDialogToShow={setDialogToShow}
                timezoneFilter={timezoneFilter}
                dateRange={dateRange}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                defaultData={defaultData}
                downloadCampaignList={downloadCampaignList}
                isCampaignListDownloading={downloadMutation.isLoading}
                ioDetail={ioDetail}
                userData={userData}
                isVldEnabled={isVldEnabled}
                hadVldGenerated={hadVldGenerated}
              />
            ) : null}
            <Box sx={{ height: 100 }}>
              <CampaignList
                isBidShadingApplicable={isBidShadingEnabled as boolean}
                data={flatData}
                columnDef={getCampaignsTableColDef(ioDetail)}
                sorting={sorting}
                totalRecords={totalRecords}
                filteredRecords={filteredRecords}
                onSortingChange={setSorting}
                rowIdKey="campaignId"
                pageSize={DEFAULT_PAGE_SIZE}
                loading={campaignListLoading}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                footerData={footerData}
                isFiltered={!!search}
                onFetchRows={() => {
                  // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
                  setTimeout(fetchNextPage, 100);
                }}
                isIoDetailsDirty={isIoDetailsDirty}
                openIoSaveDialog={openIoSaveDialog}
                setOpenIoSaveDialog={setOpenIoSaveDialog}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                defaultDeselectedColumns={defaultDeselectedColumns}
                overlay={overlay}
                ioDetail={ioDetail}
              />
            </Box>
          </Paper>
        </Col>
      </Row>
      {renderDialog()}
      {showCampaignCreateDialog && (
        <CreateCampaignDialog
          onClose={() => setShowCampaignCreateDialog(false)}
          onCreate={(campaignType: CampaignTypeByName) => {
            if (isIoDetailsDirty) {
              setOpenIoSaveDialog(true);
            } else if (campaignType === 'advanced') {
              history.push(`/campaign-create?ioId=${ioId}`);
            } else if (campaignType === 'pg') {
              history.push(`/campaign-create-pg?ioId=${ioId}`);
            }
          }}
        />
      )}
    </Box>
  );
};

const mapState = (state: AppState) => ({
  owId: state.auth.userData.owId,
  ioId: state.app.savedIoId,
  userData: state.auth.userData,
});

const mapActions = {
  setBidShadingApplicability: advertiserActions.setBidShadingApplicability,
};

export const CampaignListWrapper = connect(mapState, mapActions)(CampaignListWrapperComponent);
